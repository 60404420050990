import "assets/scss/dashboard.scss";

import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import LineWaitTimeFormControlListSelector from "pages/charts/LineWaitChart/LineWaitTimeFormControlListSelector.jsx";
import { getDataApi } from "services/appApi";

function LineWaitTimeChartPage() {
  const [processes, setProcesses] = useState([]);
  const [lines, setLines] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [areMultipleChartAreasShown, setAreMultipleChartAreasShown] =
    useState(false);
  const [loadingErrors, setLoadingErrors] = useState([]);

  const toggleSecondChartArea = () => {
    setAreMultipleChartAreasShown(!areMultipleChartAreasShown);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        let res = await getDataApi("/api/lov/getProcLines");
        //        let res = await getDataApi("EffOpTimePageLoad");
        if (res) {
          let successFlag = res.data[0];
          console.log("Response: ",res);
          console.log("Success Flag",successFlag);
          if (successFlag === "Y") {
            setProcesses(res.data[1]);
            console.log("res1", res.data[1]);
            setLines(res.data[2]);
            console.log("res2", res.data[2]);
            setIsLoading(false);
          } else {
            setLoadingErrors([{ message: res.data[3] || 'Unknown error occurred' }]);
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
          setLoadingErrors([{ message: 'No response from server' }]);
        }
      } catch (err) {
        console.log(err);
        const errorMessage = err.response?.status === 500 
        ? 'Data/export unavailable' 
        : 'Unable to pull data, please try again later'; 
      setLoadingErrors([{ message: errorMessage }]);
      }
    };
    fetchData();
  }, []);

  const getLoadingHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="row d-flex justify-content-center m-5">
          <div className="col-auto">
            <div className="card shadow-sm border-primary">
              <div className="card-header bg-primary text-white">
                <h2 className="mb-0">
                  Line Wait Times Chart
                </h2>
              </div>
              <div className="card-body text-center">
                <h3>
                  <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                  The page is loading...
                </h3>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const getLoadedHeaderContents = () => {
    return (
      <React.Fragment>
        <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
           Line Wait Times Chart
           <button
          type="button"
          id="btnSecondChart"
          className={
            areMultipleChartAreasShown
              ? "btn btn-danger mt-2 mt-md-0 ms-md-3"
              : "btn btn-success mt-2 mt-md-0 ms-md-3"
          }
          onClick={toggleSecondChartArea}
        >
          
          <i
            className={
              areMultipleChartAreasShown
                ? "fa fa-square-minus me-2" 
                : "fa fa-square-plus me-2"   
            }
          ></i>
          {areMultipleChartAreasShown ? "Remove" : "Add"} 2<sup>nd</sup> Chart
        </button>
          </h2>
      </React.Fragment>
    );
  };

  const getErrrorMsgContents = () => {
    return (
      <React.Fragment>
        {loadingErrors.length > 0 && (
        <div className="row mt-5 justify-content-md-center">
          <div className="col-6">
            <div className="card border-secondary">
              <div className="card-header bg-dark text-white">
                <h5>Line Wait Times Charts are Unavailable</h5>
              </div>
              <div className="card-body card-body-error rounded-bottom">
                <ul class="mb-0" style={{listStyleType: 'none', padding: 0, margin: 0}}>
                  {loadingErrors.map((error, index) => (
                    <li key={index}>{error.message}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      </React.Fragment>
    );
  };

  const getFormControls = (chartId) => {
    return (
      <React.Fragment>
              {!isLoading && (
                  <LineWaitTimeFormControlListSelector
                    processes={processes}
                    lines={lines}
                    chartId={chartId}
                  />
              )}
      </React.Fragment>
    );
  };

  return (
    <div>
          {isLoading && loadingErrors.length === 0 ? getLoadingHeaderContents() : getLoadedHeaderContents()}
          <div className="row">
            {loadingErrors.length > 0
              ? getErrrorMsgContents()
              : getFormControls("EffOpTmChart1")}

            {loadingErrors.length <= 0 && areMultipleChartAreasShown
              ? getFormControls("EffOpTmChart2")
              : ""}
          </div>
        </div>
  );
}

export default LineWaitTimeChartPage;
