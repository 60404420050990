import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "assets/scss/dashboard.scss";
import "assets/css/charts_form_controls.css";
import React, { useState, useRef, useLayoutEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "assets/css/grid_table.css";
import { getDataApi, saveApi, loadApi } from "services/appApi";
import { pecaPageRows, pecaRowsPerPageOptions } from "pages/common/envPECA";

const DxpDataLoggersEventTrig  = () => {
  let emptyRow = {
    group_name: "",
    file_name: "",
    logging_path: "",
    triger_id: null,
    foreign_key_table_name: "",
    foreign_key_id: null,
    triger_tag1: "",
    condition1: null,
    target_value1: "",
    triger_tag2: null,
    condition2: null,
    target_value2: null,
    b_and: null,
    watch_cycle: null,
    interface_error: "",
    created_by: null,
    creation_date: "",
    last_updated_by: null,
    last_update_date: "",
  };

  const [rows, setRows] = useState([]);
  const [rowDialog, setRowDialog] = useState(false);
  const [showproductDialog, setShowProductDialog] = useState(false);
  const [row, setRow] = useState(emptyRow);
  const [selectedRows, setSelectedRows] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const toast = useRef(null);
  const dt = useRef(null);

  // Fetch Machines when the component mounts
  useLayoutEffect(() => {
    const fetchRows = async () => {
      try {
        setIsLoading(true);
        const response = await loadApi("", "/api/setup/dxpTrigers/selRows");
        console.log("Fetched Data:", response.data);
        console.log(response.data[0]);
        console.log(response.data[1]);
        if (response.data.length > 0) {
          let successFlag = response.data[0];
          if (successFlag === "Y") {
            console.log("success=Y");
            setRows(response.data[1]);
            setIsLoading(false);
          } else {
            //setRows(null);
            setRows([]);
            setIsLoading(false);
            const errorInfo = response.data[2];
 
            // Set the error message and display the dialog
            setErrorMessage(errorInfo);
            setErrorDialogVisible(true);
          }
          //setSearch(response.data);
        } else {
          //setRows(null);
          setRows([]);
          setIsLoading(false);
        }

        //setRows(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchRows();
  }, []);


  const hideDialog = () => {
    setSubmitted(false);
    setRowDialog(false);
    setShowProductDialog(false);
    setErrorDialogVisible(false);
  };

  const showProduct = (row) => {
    setRow({ ...row });
    setShowProductDialog(true);
}


const exportCSV = () => {
  dt.current.exportCSV();
};
const getLoadingHeaderContents = () => {
  return (
    <React.Fragment>
      <div className="row d-flex justify-content-center m-5">
        <div className="col-auto">
          <div className="card shadow-sm border-primary">
            <div className="card-header bg-primary text-white">
              <h2 className="mb-0">
              DXP Loggers Event Trigers
              </h2>
            </div>
            <div className="card-body text-center">
              <h3>
                <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                The page is loading...
              </h3>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}

const getLoadedHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="header-container">
          <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
          DXP Loggers Event Trigers
          </h2>
          <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
            <div className="header-container p-1">
                <style>
                {`
                  input::placeholder {
                    font-size: 17px; 
                  }
                `}
              </style>
            <span className="p-input-icon-right w-full md:w-auto"> 
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
            className="w-full lg:w-auto text-sm p-3"
            style={{ fontSize: '17px' }} 
          />
        </span>
            </div>
          </h2>
        </div>
      </React.Fragment>
    );
  }


  const header = (
    <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
      {/* <span className="p-input-icon-right w-full md:w-auto">
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto"
        />
      </span> */}
       <div className="mt-1 md:mt-0 flex md:flex-1 justify-content-end">
        <Button
          icon="pi pi-upload"
          className="p-button-primary p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    </div>
  );


  const readBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-primary"
          onClick={() => showProduct(rowData)}
          tooltip="View"
          tooltipOptions={{ position: "bottom" }}
        />
      </React.Fragment>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="col mx-2">
      {isLoading ? getLoadingHeaderContents() : getLoadedHeaderContents()}
      {!isLoading && (
      <div className="card">
        {/* <div className="text-3xl text-800 font-bold mb-4">DXP Loggers Event Trigers</div> */}
        <DataTable
          ref={dt}
          value={rows}
          scrollable scrollHeight="flex"
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="triger_id"
          className="custom-datatable-border"
          tableStyle={{ minWidth: '50rem' }}
          paginator
          rows={pecaPageRows}
          rowsPerPageOptions={pecaRowsPerPageOptions}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data"
          globalFilter={globalFilter}
          header={header}
          emptyMessage="No data found"
          stripedRows
          showGridlines
        >
           
          <Column body={readBodyTemplate} exportable={false}  style={{width:'0.75rem' }}></Column>
          <Column field="group_name" header="Group Name" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="file_name" header="File Name" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="logging_path" header="File Path" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="foreign_key_id" header="Access Path" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="triger_tag1" header="Triger Tag1" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="condition1" header="Condition1" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="target_value1" header="Target Value1" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="triger_tag2" header="Triger Tag2" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="condition2" header="Condition2" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="target_value2" header="Target Value2" sortable style={{whiteSpace: 'nowrap' }}></Column>
        </DataTable>
      </div>
        )}

      <Dialog visible={showproductDialog} style={{ width: '600px' }} header="DXP Loggers Event Trigers" modal className="p-fluid" onHide={hideDialog}>

                <label htmlFor="group_name">Group Name</label>
                    <div className="box">
                        {row.group_name}
                    </div>

                <label htmlFor="file_name">File Name</label>
                    <div className="box">
                        {row.file_name}
                    </div>

                <label htmlFor="logging_path">File Path</label>
                    <div className="box">
                        {row.logging_path}
                    </div>

                <label htmlFor="foreign_key_id">Access Path</label>
                    <div className="box">
                        {row.foreign_key_id}
                    </div>

                <label htmlFor="triger_tag1">Triger Tag1</label>
                    <div className="box">
                        {row.triger_tag1}
                    </div>

                <label htmlFor="condition1">Condition1</label>
                    <div className="box">
                        {row.condition1}
                    </div>

                <label htmlFor="target_value1">Target Value1</label>
                    <div className="box">
                        {row.target_value1}
                    </div>

                <label htmlFor="triger_tag2">Triger Tag2</label>
                    <div className="box">
                        {row.triger_tag2}
                    </div>

                <label htmlFor="condition2">Condition2</label>
                    <div className="box">
                        {row.condition2}
                    </div>

                <label htmlFor="target_value2">Target Value2</label>
                    <div className="box">
                        {row.target_value2}
                    </div>

            </Dialog>
            <Dialog 
                header="API Error" 
                visible={errorDialogVisible} 
                style={{ width: '400px' }} 
                onHide={hideDialog} 
                footer={
                    <Button label="OK" onClick={hideDialog} className="p-button-primary p-button-rounded" />
                }
            >    
             <p>

                    {errorMessage}
                </p>
            </Dialog>
            </div>
    </div>
  );
};

export default DxpDataLoggersEventTrig;


