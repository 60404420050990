import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "assets/scss/dashboard.scss";
import "assets/css/charts_form_controls.css";
import React, { useState, useRef, useLayoutEffect } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import "assets/css/grid_table.css";
import { getDataApi, saveApi } from "../../../services/appApi";
import { pecaPageRows, pecaRowsPerPageOptions } from "pages/common/envPECA";

const TaggingPG = () => {
  let emptyRow = {
    tag_name: "",
    comm_name: "",
    opc_item_id: "",
    unit: "",
    bbcd: false,
    boffset: 0,
    bpower: 0,
    bsqrt: false,
    bexp: false,
    bscale: false,
    min: 0,
    max: 0,
    eng_min: 0,
    eng_max: 0,
    brate_convert: false,
    rate: 0,
    digit: 0,
    change_rate: 0,
    bstring_table: false,
    comments: "",
    array_element: 0,
    array_num: 0,
  };

  const [rows, setRows] = useState([]);
  const [rowDialog, setRowDialog] = useState(false);
  const [deleteRowDialog, setDeleteRowDialog] = useState(false);
  const [row, setRow] = useState(emptyRow);
  const [selectedRows, setSelectedRows] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const toast = useRef(null);
  const dt = useRef(null);

  // Fetch rows data on component mount
  useLayoutEffect(() => {
    const fetchRows = async () => {
      try {
        setIsLoading(true);
        const response = await getDataApi("/api/setup/dxpTags/selRows");
        console.log(response.data); // Check the structure of the fetched data
        if (response.data.length > 0) {
          let successFlag = response.data[0];
          if (successFlag === "Y") {
            setRows(response.data[1]); // Ensure response.data[1] has the expected structure
            setIsLoading(false);
          } else {
            setRows([]);
            setIsLoading(false);
            const errorInfo = response.data[2];
            // Set the error message and display the dialog
            setErrorMessage(errorInfo);
            setErrorDialogVisible(true);
          }
        } else {
          setRows([]);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    fetchRows();
  }, []);

  const openNew = () => {
    setRow(emptyRow);
    setSubmitted(false);
    setRowDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setRowDialog(false);
    setErrorDialogVisible(false);
  };

  const editRow = (row) => {
    setRow({ ...row });
    setRowDialog(true);
  };

  const getLoadingHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="row d-flex justify-content-center m-5">
          <div className="col-auto">
            <div className="card shadow-sm border-primary">
              <div className="card-header bg-primary text-white">
                <h2 className="mb-0">
                Tag Management
                </h2>
              </div>
              <div className="card-body text-center">
                <h3>
                  <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                  The page is loading...
                </h3>
              </div>
            </div>
          </div>
        </div>
  
      </React.Fragment>
    );
  }
  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].tag_id === id) {
        index = i;
        break;
      }
    }
    return index;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const getLoadedHeaderContents = () => {
          return (
            <React.Fragment>
              <div className="header-container">
                <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
                Tag Management
                </h2>
                <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
                  <div className="header-container p-1">
                      <style>
                      {`
                        input::placeholder {
                          font-size: 17px; 
                        }
                      `}
                    </style>
                  <span className="p-input-icon-right w-full md:w-auto"> 
                <InputText
                  type="search"
                  onInput={(e) => setGlobalFilter(e.target.value)}
                  placeholder="Search..."
                  className="w-full lg:w-auto text-sm p-3"
                  style={{ fontSize: '17px' }} 
                />
              </span>
                  </div>
                </h2>
              </div>
            </React.Fragment>
          );
        }

  const header = (
    <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
          <div className="mt-1 md:mt-0 flex md:flex-1 justify-content-end">
        <Button
          icon="pi pi-upload"
          className="p-button-primary p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    </div>
  );
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded mr-2"
          onClick={() => editRow(rowData)}
          tooltip="View"
          tooltipOptions={{ position: "bottom" }}
        />
      </React.Fragment>
    );
  };

  
  return (
    <div>
    <div className="col mx-2">
    {isLoading ? getLoadingHeaderContents() : getLoadedHeaderContents()}
    {!isLoading && (
    <div className="card">
      <Toast ref={toast} />
      {/* <div className="text-3xl text-800 font-bold mb-4">Tag Management</div> */}
      <DataTable
        ref={dt}
        value={rows}
        scrollable scrollHeight="flex"
        selection={selectedRows}
        onSelectionChange={(e) => setSelectedRows(e.value)}
        dataKey="tag_id"
        className="custom-datatable-border datatable-responsive"
        tableStyle={{ minWidth: '50rem' }}
        paginator
        rows={pecaPageRows}
        rowsPerPageOptions={pecaRowsPerPageOptions}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data"
        globalFilter={globalFilter}
        emptyMessage="No Tags Found."
        header={header}
        responsiveLayout="scroll"
        stripedRows
        showGridlines
      >
        
        <Column
          body={actionBodyTemplate}
          exportable={false}
          style={{width:'0.75rem' }}
        />
        <Column
          field="tag_name"
          header="Tag Name"
          sortable
        ></Column>
        <Column
          field="comm_name"
          header="Comm Name"
          sortable
        ></Column>
        <Column
          field="opc_item_id"
          header="Opc Item ID"
          sortable
        ></Column>
        <Column
          field="comments"
          header="Comments"
          sortable
        ></Column>
        
      </DataTable>
      <Dialog
        visible={rowDialog}
        style={{ width: "600px" }}
        header="Tag Details"
        modal
        className="p-fluid"
      //  footer={rowDialogFooter}
        onHide={hideDialog}
      >
        
          <label htmlFor="tag_name">Tag Name</label>
          <div className="box">
                        {row.tag_name}
                    </div>
          
          <label htmlFor="comm_name">Comm Name</label>
          <div className="box">
                        {row.comm_name}
                    </div>

          <label htmlFor="opc_item_id">Opc Item ID</label>
          <div className="box">
                        {row.opc_item_id}
                    </div>

          <label htmlFor="comments">Comments</label>
          <div className="box">
                        {row.comments}
                    </div>
        {/* Add other fields as required */}
      </Dialog>

      <Dialog 
                header="API Error" 
                visible={errorDialogVisible} 
                style={{ width: '400px' }} 
                onHide={hideDialog} 
                footer={
                    <Button label="OK" onClick={hideDialog} className="p-button-primary p-button-rounded" />
                }
            >    
             <p>

                    {errorMessage}
                </p>
            </Dialog>
    </div>
  )}
  </div>
  </div>
  );
};

export default TaggingPG;
