import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "assets/scss/dashboard.scss";
import "assets/css/charts_form_controls.css";
import React, { useState, useRef, useLayoutEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "assets/css/grid_table.css";
import { getDataApi, saveApi, loadApi } from "services/appApi";
import { pecaPageRows, pecaRowsPerPageOptions } from "pages/common/envPECA";

const DxpDataLoggersLogDet  = () => {
  let emptyRow = {
    group_name: "",
    file_name: "",
    logging_path: "",
    format_id: null,
    foreign_key_table_name: "",
    foreign_key_id: null,
    field_name: "",
    field_item_type: null,
    tag_name: "",
    field_data_type: null,
    report_calc: null,
    visible_graph_line: null,
    graph_line_color: null,
    graph_eng_min: null,
    graph_eng_max: null,
    b_where_field: null,
    invarid_null: null,
    swiging_door: null,
    swiging_rate: null,
    argb_graph_default_color: -null,
    high_low1: null,
    high_low2: null,
    db_threshold1: null,
    argb_graph_threshold1_color: null,
    db_threshold2: null,
    argb_graph_threshold2_color: null,
    interface_error: "",
    created_by: null,
    creation_date: "",
    last_updated_by: null,
    last_update_date: "",
  };

  const [rows, setRows] = useState([]);
  const [rowDialog, setRowDialog] = useState(false);
  const [showproductDialog, setShowProductDialog] = useState(false);
  const [row, setRow] = useState(emptyRow);
  const [selectedRows, setSelectedRows] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingErrors, setLoadingErrors] = useState([]);
  const [wasDataFetchingSuccessful, setWasDataFetchingSuccessful] =useState(true);
  const [groupName, setGroupName]=useState("");
  const [fileName, setFileName]=useState("");
  const toast = useRef(null);
  const dt = useRef(null);

  const p_param = {
    p_foreign_key_table_name: "LOGGING",
  };

  // Fetch Machines when the component mounts
  const handleLoadData = async () => {
    const p_param = {
      p_group_name: groupName,
      p_file_name: fileName,
   };
   console.log(p_param);
    try {
      setIsLoading(true);
      const response = await loadApi(p_param,"/api/setup/dxpLoggingDtls/selRows");
      console.log("Fetched Data:", response.data);
      console.log(response.data[0]);
      console.log(response.data[1]);
      if (response.data.length > 0) {
        let successFlag = response.data[0];
        if (successFlag === "Y") {
          console.log("success=Y");
          setRows(response.data[1]);
          setIsLoading(false);
          setWasDataFetchingSuccessful(false);
        } else {
          //setRows(null);
          setRows([]);
          setIsLoading(false);
          setWasDataFetchingSuccessful(true);
          const errorInfo = response.data[2];
          // Set the error message and display the dialog
          setErrorMessage(errorInfo);
          setErrorDialogVisible(true);
        }
        //setSearch(response.data);
      } else {
        //setRows(null);
        setRows([]);
        setIsLoading(false);
        setWasDataFetchingSuccessful(true);
      }

      //setRows(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const hideDialog = () => {
    setSubmitted(false);
    setRowDialog(false);
    setShowProductDialog(false);
    setErrorDialogVisible(false);
  };

  const showProduct = (row) => {
    setRow({ ...row });
    setShowProductDialog(true);
}

const exportCSV = () => {
  dt.current.exportCSV();
};
const getLoadingHeaderContents = () => {
  return (
    <React.Fragment>
      <div className="row d-flex justify-content-center m-5">
        <div className="col-auto">
          <div className="card shadow-sm border-primary">
            <div className="card-header bg-primary text-white">
              <h2 className="mb-0">
              DXP Data Logging Details
              </h2>
            </div>
            <div className="card-body text-center">
              <h3>
                <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                The page is loading...
              </h3>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}


  const header = (
    <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
      {/* <span className="p-input-icon-right w-full md:w-auto">
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto"
        />
      </span> */}
       <div className="mt-1 md:mt-0 flex md:flex-1 justify-content-end">
        <Button
          icon="pi pi-upload"
          className="p-button-primary p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    </div>
  );
  const getLoadedHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="header-container">
        <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
        DXP Data Logging Details
            {/* <div className="header-container p-1">
                <style>
                {`
                  input::placeholder {
                    font-size: 17px;
                  }
                `}
              </style>
            <span className="p-input-icon-right w-full md:w-auto"> 
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
            className="w-full lg:w-auto text-sm p-3"
            style={{ fontSize: '17px' }} 
          />
        </span>
            </div> */}
          </h2>
        </div>
      </React.Fragment>
    );
  }
  const getErrrorMsgContents = () => {
    return (
      <React.Fragment>
        <div class="row mt-5 justify-content-md-center">
          <div class="col-3">
            <div class="card border-secondary">
              <div class="card-header bg-dark text-white">
              DXP Data Logging Details table is Unavailable
              </div>
              <div class="card-body card-body-error rounded-bottom">
                <ul>
                  {loadingErrors.map((error) => (
                    <li>
                      error
                    </li>
                  ))}
  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }


  const readBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-primary"
          onClick={() => showProduct(rowData)}
          tooltip="View"
          tooltipOptions={{ position: "bottom" }}
        />
      </React.Fragment>
    );
  };
  const getSearchControls = () => (
    <React.Fragment>
      <div className="col-auto form-group ps-2 pe-0 d-flex flex-column">
      <label className="form-label">Search</label>
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
            className="input-outline" />
             </div>
    </React.Fragment>
  );
  const getGroupNameControl = () => (
    <React.Fragment>
     <div className="col-auto form-group ps-2 pe-0 d-flex flex-column">
      <label className="form-label">Group Name</label>
            <InputText 
            type="text"
            id = "GroupName"
            name="GroupName" 
            onChange={(e) => setGroupName(e.target.value)}
            placeholder="Enter group name"
            className="input-outline" />
             </div>
    </React.Fragment>
  );
  const getFileNameControl = () => (
    <React.Fragment>
     <div className="col-auto form-group ps-2 pe-0 d-flex flex-column">
      <label className="form-label">File Name</label>
            <InputText 
            type="text"
            id = "FileName"
            name="FileName" 
            onChange={(e) => setFileName(e.target.value)}
            placeholder="Enter file name"
            className="input-outline" />
             </div>
    </React.Fragment>
  );
  const getGoButtonControl = () => (
    <React.Fragment>
        <div className="col-auto d-flex align-items-center justify-content-center ps-2 pe-0" style={{paddingTop:'31px'}}>
          <button
            type="button"
            className="btn btn-primary load-button"
            id={"chartAreaId"}
            onClick={handleLoadData}
            disabled={isLoading}
          >
            <i
            className={
              isLoading ? "fa-duotone fa-fw fa-rotate fa-spin" : "fa-duotone fa-fw fa-rotate"
            }
          ></i>
          <span>{isLoading ? "Loading" : "Go"}</span>
          </button>
        </div>
    </React.Fragment>
    );

  return (
    <div>
      <Toast ref={toast} />
      <div className="col mx-2">
      {getLoadedHeaderContents()}
      {loadingErrors.length > 0 && getErrrorMsgContents()}
      <div className="row">
      <div className="col">
        <div className="row justify-content-center">
          {getSearchControls()} 
          {getGroupNameControl()}
          {getFileNameControl()}
          {getGoButtonControl()}
        </div>
      {!wasDataFetchingSuccessful && (
      <div className="card">
        {/* <div className="text-3xl text-800 font-bold mb-4">DXP Data Logger Logging Details</div> */}
        <DataTable
          ref={dt}
          value={rows}
          scrollable scrollHeight="flex"   
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="format_id"
          className="custom-datatable-border"
          tableStyle={{ minWidth: '50rem' }}
          paginator
          rows={pecaPageRows}
          rowsPerPageOptions={pecaRowsPerPageOptions}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data"
          globalFilter={globalFilter}
          header={header}
          emptyMessage="No data found"
          stripedRows
          showGridlines
        >
          
          <Column body={readBodyTemplate} exportable={false} style={{width:'0.75rem' }}></Column>
          <Column field="group_name" header="Group Name" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="file_name" header="File Name" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="logging_path" header="File Path" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="field_name" header="Field Name" sortable style={{whiteSpace: 'nowrap' }} ></Column>
          <Column field="field_item_type" header="Item Type" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="tag_name" header="Tag Name" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="field_data_type" header="File Data Type" sortable></Column>
        </DataTable>
      </div>
       )}
       </div>
       </div>

      <Dialog visible={showproductDialog} style={{ width: '600px' }} header="DXP Data Logging Details" modal className="p-fluid" onHide={hideDialog}>
                
                <label htmlFor="group_name">Group Name</label>
                    <div className="box">
                        {row.group_name}
                    </div>

                <label htmlFor="file_name">File Name</label>
                    <div className="box">
                        {row.file_name}
                    </div>

                <label htmlFor="logging_path">File Path</label>
                    <div className="box">
                        {row.logging_path}
                    </div>

                <label htmlFor="field_name">Field Name</label>
                    <div className="box">
                        {row.field_name}
                    </div>

                <label htmlFor="field_item_type">Item Type</label>
                    <div className="box">
                        {row.field_item_type}
                    </div>

                <label htmlFor="tag_name">Tag Name</label>
                    <div className="box">
                        {row.tag_name}
                    </div>

                <label htmlFor="field_data_type">File Data Type</label>
                    <div className="box">
                        {row.field_data_type}
                    </div>

            </Dialog>
            <Dialog 
                header="API Error" 
                visible={errorDialogVisible} 
                style={{ width: '400px' }} 
                onHide={hideDialog} 
                footer={
                    <Button label="OK" onClick={hideDialog} className="p-button-primary p-button-rounded" />
                }
            >    
             <p>

                    {errorMessage}
                </p>
            </Dialog>
            </div>
    </div>
  );
};

export default DxpDataLoggersLogDet;


