import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "assets/scss/dashboard.scss";
import "assets/css/charts_form_controls.css";
import React, { useState, useRef, useLayoutEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "assets/css/grid_table.css";
import { getDataApi, saveApi, loadApi } from "services/appApi";
import { pecaPageRows, pecaRowsPerPageOptions } from "pages/common/envPECA";

const ErrorSummary  = () => {
  let emptyRow = {
    data_source_seq: "",
    data_source: "",
    ora_error_message: "",
    count_errors: ""
  };

  const [rows, setRows] = useState([]);
  const [rowDialog, setRowDialog] = useState(false);
  const [showproductDialog, setShowProductDialog] = useState(false);
  const [row, setRow] = useState(emptyRow);
  const [selectedRows, setSelectedRows] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const toast = useRef(null);
  const dt = useRef(null);
  

  // Fetch Machines when the component mounts
  useLayoutEffect(() => {
    const fetchRows = async () => {
      try {
        setIsLoading(true);
        const response = await loadApi("","/api/txn/getPollDataErrCnts");
        console.log("Fetched Data:", response.data);
        console.log(response.data[0]);
        console.log(response.data[1]);
        if (response.data.length > 0) {
          let successFlag = response.data[0];
          if (successFlag === "Y") {
            console.log("success=Y");
            setRows(response.data[1]);
            setIsLoading(false);
          } else {
            //setRows(null);
            setRows([]);
            setIsLoading(false);
            const errorInfo = response.data[2];

            // Set the error message and display the dialog
            setErrorMessage(errorInfo);
            setErrorDialogVisible(true);
          }
          //setSearch(response.data);
        } else {
          //setRows(null);
          setRows([]);
          setIsLoading(false);
        }

        //setRows(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchRows();
  }, []);


  const hideDialog = () => {
    setSubmitted(false);
    setRowDialog(false);
    setShowProductDialog(false);
    setErrorDialogVisible(false);
  };

  const showProduct = (row) => {
    setRow({ ...row });
    setShowProductDialog(true);
}

const exportCSV = () => {
  dt.current.exportCSV();
};

const getLoadingHeaderContents = () => {
  return (
    <React.Fragment>
      <div className="row d-flex justify-content-center m-5">
        <div className="col-auto">
          <div className="card shadow-sm border-primary">
            <div className="card-header bg-primary text-white">
              <h2 className="mb-0">
              Dxp Poll Data Interface Errors Summary
              </h2>
            </div>
            <div className="card-body text-center">
              <h3>
                <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                The page is loading...
              </h3>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}

const getLoadedHeaderContents = () => {
                return (
                  <React.Fragment>
                    <div className="header-container">
                      <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
                      Dxp Poll Data Interface Errors Summary
                      </h2>
                      <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
                        <div className="header-container p-1">
                            <style>
                            {`
                              input::placeholder {
                                font-size: 17px; 
                              }
                            `}
                          </style>
                        <span className="p-input-icon-right w-full md:w-auto"> 
                      <InputText
                        type="search"
                        onInput={(e) => setGlobalFilter(e.target.value)}
                        placeholder="Search..."
                        className="w-full lg:w-auto text-sm p-3"
                        style={{ fontSize: '17px' }} 
                      />
                    </span>
                        </div>
                      </h2>
                    </div>
                  </React.Fragment>
                );
              }

  const header = (
    <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
      {/* <span className="p-input-icon-right w-full md:w-auto">
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto"
        />
      </span> */}
      <div className="mt-1 md:mt-0 flex md:flex-1 justify-content-end">
        <Button
          icon="pi pi-upload"
          className="p-button-primary p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    </div>
  );


  const readBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-primary"
          onClick={() => showProduct(rowData)}
          tooltip="View"
          tooltipOptions={{ position: "bottom" }}
        />
      </React.Fragment>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="col mx-2">
      {isLoading ? getLoadingHeaderContents() : getLoadedHeaderContents()}
      {!isLoading && (
      <div className="card">
        {/* <div className="text-3xl text-800 font-bold mb-4">Dxp Poll Data Interface Errors Summary</div> */}
        <DataTable
          ref={dt}
          value={rows}
          scrollable scrollHeight="flex"     
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="file_id"
          className="custom-datatable-border"
          tableStyle={{ minWidth: '50rem' }}
          paginator
          rows={pecaPageRows}
          rowsPerPageOptions={pecaRowsPerPageOptions}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data"
          globalFilter={globalFilter}
          header={header}
          emptyMessage="No data found"
          stripedRows
          showGridlines
        >
         
          <Column body={readBodyTemplate} exportable={false} style={{width:'0.75rem' }}></Column>
          <Column field="data_source_seq" header="Seq" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="data_source" header="Source" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="ora_error_message" header="Error Message" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="count_errors" header="Count" sortable style={{whiteSpace: 'nowrap', textAlign: 'right',width:'12px' }}></Column>
        </DataTable>
      </div>
      )}

      <Dialog visible={showproductDialog} style={{ width: '600px' }} header="Dxp Poll Data Interface Errors Summary" modal className="p-fluid" onHide={hideDialog}>
                
                <label htmlFor="data_source_seq">Seq</label>
                    <div className="box">
                        {row.data_source_seq}
                    </div>

                <label htmlFor="data_source">Source</label>
                    <div className="box">
                        {row.data_source}
                    </div>

                <label htmlFor="ora_error_message">Error Message</label>
                    <div className="box">
                        {row.ora_error_message}
                    </div>

                <label htmlFor="count_errors">Count</label>
                    <div className="box">
                        {row.count_errors}
                    </div>

            </Dialog>
            <Dialog 
                header="API Error" 
                visible={errorDialogVisible} 
                style={{ width: '400px' }} 
                onHide={hideDialog} 
                footer={
                    <Button label="OK" onClick={hideDialog} className="p-button-primary p-button-rounded" />
                }
            >    
             <p>

                    {errorMessage}
                </p>
            </Dialog>
            </div>
    </div>
  );
};

export default ErrorSummary;

