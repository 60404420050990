import "assets/VisualizationAssets/_lib/bootstrap/5.3.2/dist/css/bootstrap.min.css";
import "assets/VisualizationAssets/_lib/font-awesome/6.4.2/css/fontawesome.min.css";
import "assets/VisualizationAssets/_lib/font-awesome/6.4.2/css/duotone.min.css";
import "assets/VisualizationAssets/_css/transitionLibrary.css";
import "assets/VisualizationAssets/_images/favicon.ico";
// import "assets/VisualizationAssets/_lib/jquery/3.7.1/dist/jquery.js";
// import "assets/VisualizationAssets/_lib/bootstrap/5.3.2/dist/js/bootstrap.bundle.js";
//import "assets/VisualizationAssets/_lib/jquery/3.7.1/dist/jquery.js";
//import "assets/VisualizationAssets/_lib/bootstrap/5.3.2/dist/js/bootstrap.bundle.min.js";
import { PrimeReactProvider } from "primereact/api";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "assets/style/dark.scss";
import "primereact/resources/themes/lara-light-cyan/theme.css";
//import "primeflex/primeflex.scss";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import SideMenu from "Layouts/SideMenu";
import EffOpTimeChartPage from "pages/charts/EffOpTime/EffOpTimeChartPage";
import EffUnitChartPage from "pages/charts/EffUnit/EffUnitChartPage";
import MachineStopsTimeChartPage from "pages/charts/MachineStops/MachineStopsTimeChartPage";
import VACheckChartPage from "pages/charts/VAChecker/VACheckChartPage";
import ParetoMachStopsChartPage from "pages/charts/ParetoMachStops/ParetoMachStopsChartPage";
import TPAInsNGChartPage from "pages/charts/TPAInsNG/TPAInsNGChartPage";
import ParetoRejectMachStopsChartPage from "pages/charts/ParetoRejectsMach/ParetoRejectMachStopsChartPage";
import LineWaitTimeChartPage from "pages/charts/LineWaitChart/LineWaitTimeChartPage";
import HomePage from "pages/HomePage";
import PecaTop from "Layouts/PecaTop";
import MachinesPG from "pages/AdminPages/Setups/machines";
import MachinesAddressesPG1 from "pages/AdminPages/Setups/MachineAddress";
import PollDataArchives from "pages/AdminPages/Transactions/PollDataArchives";
import CellAssembly from "pages/Dashboards/CellAssembly";
import TPADashboarsPage from "pages/Dashboards/TPADashboards";
import DxpDataLoggers from "pages/AdminPages/DataLoggerSetups/DxpDataLoggers";
import DxpDataLoggersLogDet from "pages/AdminPages/DataLoggerSetups/DxpDataLoggersLogDet";
import DxpDataLoggersLogDetEvent from "pages/AdminPages/DataLoggerSetups/DxpDataLoggersLogDetEvent";
import DxpDataLoggersEventTrig from "pages/AdminPages/DataLoggerSetups/DxpDataLoggersEventTrig";
import EventsPG from "pages/AdminPages/DataLoggerSetups/Events";
import CommunicationsPG from "pages/AdminPages/DataLoggerSetups/Communications";
import TaggingPG from "pages/AdminPages/DataLoggerSetups/Tags";
import PollDataFiles from "pages/AdminPages/PollData/PollDataFiles";
import PollDataColumns from "pages/AdminPages/PollData/PollDataColumns";
import PollDataErrors from "pages/AdminPages/PollData/PollDataErrors";
import PollMachErrors from "pages/AdminPages/PollData/DeviceExplorerLoggingSettingError";
import ErrorSummary from "pages/AdminPages/PollData/ErrorSummary";
import LookupValues from "pages/AdminPages/Setups/System/LookupValues";
import "@fortawesome/fontawesome-free/css/all.min.css";
import OpcConfigCommPG from "pages/AdminPages/OPCServerSetups/Communications";
import OpcCommDevice from "pages/AdminPages/OPCServerSetups/OPCcommDevice";
import OpcDeviceTags from "pages/AdminPages/OPCServerSetups/OPCDeviceTags";
import SystemOptions from "pages/AdminPages/Setups/System/SystemOptions";
import OPCServerImportData from "pages/AdminPages/OPCServerSetups/OPCServerImportData";
import OPCServerProcessData from "pages/AdminPages/OPCServerSetups/OPCServerProcessData";
import SystemMessages from "pages/AdminPages/Setups/System/Messages";
import ExtractOpcDataLogger from "pages/AdminPages/Setups/ExtractOPCDataLogger";

function App() {
  return (
    <PrimeReactProvider>
      <PecaTop />
      <Router>
        <SideMenu />
        <Routes>
          {/* <Route path="/">
            <Route index element={<HomePage />} />
          </Route> */}
          <Route path="AdminPages/Setups/machines">
            <Route index element={<MachinesPG title="PDCS Machines" />} />
          </Route>
          <Route path="/AdminPages/Setups/ExtractOPCDataLogger">
            <Route index element={<ExtractOpcDataLogger title="Extract OPC/DataLogger data for Import" />} />
          </Route>
          <Route path="AdminPages/Setups/machineAddress">
            <Route
              index
              element={<MachinesAddressesPG1 title="Machine Addresses" />}
            />
          </Route>
          <Route path="AdminPages/setups/System/LookupValues">
            <Route index element={<LookupValues title="Inquire Lookup" />} />
          </Route>
          <Route path="/AdminPages/DataLoggerSetups/DxpDataLoggers">
            <Route index element={<DxpDataLoggers title="Loggings" />} />
          </Route>
          <Route path="/AdminPages/DataLoggerSetups/DxpDataLoggersLogDet">
            <Route
              index
              element={<DxpDataLoggersLogDet title="Loggings Details" />}
            />
          </Route>
          <Route path="/AdminPages/DataLoggerSetups/DxpDataLoggersLogDetEvent">
            <Route
              index
              element={<DxpDataLoggersLogDetEvent title="Event Details" />}
            />
          </Route>
          <Route path="/AdminPages/DataLoggerSetups/DxpDataLoggersEventTrig">
            <Route
              index
              element={<DxpDataLoggersEventTrig title="Event Triggers" />}
            />
          </Route>

          <Route path="/">
            <Route index element={<EffOpTimeChartPage />} />
          </Route>

          <Route path="/charts/EffOpTime">
            <Route index element={<EffOpTimeChartPage />} />
          </Route>

          <Route path="/charts/EffUnit">
            <Route index element={<EffUnitChartPage />} />
          </Route>
          <Route path="/charts/MachineStops">
            <Route index element={<MachineStopsTimeChartPage />} />
          </Route>
          <Route path="/charts/VAChecker">
            <Route index element={<VACheckChartPage />} />
          </Route>
          <Route path="/charts/ParetoMachStops">
            <Route index element={<ParetoMachStopsChartPage />} />
          </Route>
          <Route path="/charts/TPAInsNG">
            <Route index element={<TPAInsNGChartPage />} />
          </Route>
          <Route path="/charts/ParetoRejectsMach">
            <Route index element={<ParetoRejectMachStopsChartPage />} />
          </Route>
          <Route path="/charts/LineWaitChart">
            <Route index element={<LineWaitTimeChartPage />} />
          </Route>
          <Route path="/AdminPages/Transactions/PollDataArchives">
            <Route
              index
              element={<PollDataArchives title="Poll Data Archives" />}
            />
          </Route>
          <Route path="/Dashboards/CellAssembly">
            <Route index element={<CellAssembly title="Cell Assembly" />} />
          </Route>
          <Route path="/Dashboards/TPADashboards">
            <Route index element={<TPADashboarsPage title="TPA Dashboard" />} />
          </Route>
          <Route path="/AdminPages/DataLoggerSetups/Events">
            <Route index element={<EventsPG title="Events" />} />
          </Route>
          <Route path="/AdminPages/DataLoggerSetups/Communications">
            <Route
              index
              element={<CommunicationsPG title="Communications" />}
            />
          </Route>
          <Route path="/AdminPages/DataLoggerSetups/Tags">
            <Route index element={<TaggingPG title="Tags" />} />
          </Route>
          <Route path="/AdminPages/OPCServerSetups/Communications">
            <Route index element={<OpcConfigCommPG title="Communications" />} />
          </Route>
          <Route path="/AdminPages/OPCServerSetups/OPCDeviceTags">
            <Route index element={<OpcDeviceTags title="Device Tags" />} />
          </Route>
          <Route path="/AdminPages/OPCServerSetups/OPCcommDevice">
            <Route index element={<OpcCommDevice title="Devices" />} />
          </Route>
          <Route path="/AdminPages/PollData/PollDataFiles">
            <Route index element={<PollDataFiles title="Poll Data Files" />} />
          </Route>
          <Route path="/AdminPages/PollData/PollDataColumns">
            <Route
              index
              element={<PollDataColumns title="Poll Data Columns" />}
            />
          </Route>
          <Route path="/AdminPages/PollData/PollDataErrors">
            <Route
              index
              element={<PollDataErrors title="Poll Data Errors" />}
            />
          </Route>
          <Route path="/AdminPages/PollData/DeviceExplorerLoggingSettingError">
            <Route
              index
              element={
                <PollMachErrors title="Device Explorer Logging Setting Error" />
              }
            />
          </Route>

          <Route path="/AdminPages/Setup/System/Messages">
            <Route
              index
              element={<SystemMessages title="Messages" />}
            />
          </Route>

          <Route path="/AdminPages/PollData/ErrorSummary">
            <Route
              index
              element={
                <ErrorSummary title="Dxp Poll Data Interface Errors Summary" />
              }
            />
          </Route>
          <Route path="/AdminPages/Setups/System/SystemOptions">
            <Route index element={<SystemOptions title="System Options" />} />
          </Route>
          <Route path="/AdminPages/OPCServerSetups/OPCServerImportData">
            <Route
              index
              element={<OPCServerImportData title="OPC Server Import Data" />}
            />
          </Route>
          <Route path="/AdminPages/OPCServerSetups/OPCServerProcessData">
            <Route
              index
              element={<OPCServerProcessData title="OPC Server Process Data" />}
            />
          </Route>
        </Routes>
      </Router>
    </PrimeReactProvider>
  );
}

export default App;
