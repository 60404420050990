import React from 'react';
import { useEffect, useState } from 'react';
import { pecaDbName } from "services/envhttp";
import packageJson from '../../package.json';
import "assets/VisualizationAssets/_css/panasonic-header.css";

function PecaTop() {
  const [currentTime, setCurrentTime] = useState('');
  const [currentDayYear, setCurrentDayYear] = useState('')

  const updateTime = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const dayOfWeek = date.toLocaleString('default', { weekday: 'long' }); 

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const hours24 = date.getHours();
    const hours12 = String(hours24 % 12 || 12).padStart(2, '0');
    const amRm = hours24 >= 12 ? "PM" : "AM";

    const start = new Date(date.getFullYear(), 0, 0);
    const diff = date - start;
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = Math.floor(diff / oneDay);

    const formattedTime = (
      <>
        {`${day}-${month}-${year} ${dayOfWeek}, ${hours12}:${minutes}:${seconds} ${amRm}`}
      </>
    );
    
    setCurrentTime(formattedTime);

    const formattedDayYear = (
      <>
       Day of Year: {dayOfYear}
      </>
    );
    
    setCurrentDayYear(formattedDayYear);
  };

  useEffect(() => {
    updateTime();
    const timer = setInterval(updateTime, 1000);

    return () => clearInterval(timer);
  }, []);
  return (
    <div>
      <header className="d-print-none">
        <section id="panaHeader">
          <a id="panaHeaderPIW" href="https://iweb.is.jp.panasonic.com/gp/piw/en/" title="PIW Global Portal">
            PIW Global Portal
          </a>
          <div id="panaHeaderTxt">
          Panasonic Energy Corporation of America 
            {/* <br />
             */}
            <div id="panaHeaderTxtCal">
              <span id="panaHeaderTxtCalDateTime">{currentTime}</span>
              <span id="panaHeaderTxtCalDayYear">{currentDayYear}</span>
            </div>
          </div>
          <div>
          <span style={{ color:'#0041c0', fontWeight: 'bold' }}>
              <strong style={{ fontSize: '1.2em' }}> 
                {pecaDbName}
              </strong>
            </span>
            &nbsp; &nbsp; &nbsp;
            <span style={{ color:'#0041c0', fontWeight: 'bold' }}>Version: {packageJson.version}</span>
            <div id="panaHeaderRight">
            <a id="panaHeaderRightPIW" href="https://iweb.is.jp.panasonic.com/scube/energy/en/" target="_blank" title="PIW Global Portal">
              PIW Global Portal
            </a>
            <span id="panaHeaderRightLogo" title="Panasonic Intranet">
              Panasonic Intranet
            </span>
            </div>
          </div>
        </section>
      </header>
    </div>
  )
}

export default PecaTop