import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "assets/scss/dashboard.scss";
import "assets/css/charts_form_controls.css";
import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "assets/css/grid_table.css";
import "./PollDataFiles.css";
import moment from "moment";
import numeral from "numeral";
import { getDataApi, saveApi, loadApi } from "services/appApi";
import { pecaPageRows, pecaRowsPerPageOptions } from "pages/common/envPECA";

const PollDataFiles = () => {
  let emptyRow = {
    file_id: null,
    file_name: "",
    file_date: "",
    num_of_data_rows: "",
    num_of_data_columns: "",
    num_of_nonzero_data: "",
    ora_machine_id: "",
    machine_name: "",
    dxp_file_prefix: "",
    process_id: "",
    process_name: "",
    production_line_number: "",
    ora_processed_flag: "",
    ora_error_message: "",
    local_file_size_bytes: "",
    local_file_delete_date: "",
    conc_request_id: "",
    remote_ip_address: "",
    remote_user_name: "",
    remote_dir_name: "",
    orig_ftpin_date: "",
    last_ftpin_date: "",
    remote_file_size_bytes: "",
  };

  const [rows, setRows] = useState([]);
  const [rowDialog, setRowDialog] = useState(false);
  const [showproductDialog, setShowProductDialog] = useState(false);
  const [row, setRow] = useState(emptyRow);
  const [selectedRows, setSelectedRows] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingErrors, setLoadingErrors] = useState([]);
  const [wasDataFetchingSuccessful, setWasDataFetchingSuccessful] =
    useState(true);
  const [fileName, setFileName] = useState("");
  const [machineName, setMachineName] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const toast = useRef(null);
  const dt = useRef(null);
  const p_param = {
    p_file_id: null,
    p_file_name: null,
    p_process_id: null,
    p_line_number: null,
    p_machine_id: null,
    p_start_datetime: null,
    p_end_datetime: null,
    p_machine_name_like: null,
  };

  useEffect(() => {
    const fetchData = async () => {
      const yesterday = moment().subtract(1, "days").set({
        hour: 7,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

      let formattedStartDate = yesterday.format("yyyy-MM-DD HH:mm");
      setStartDate(formattedStartDate);

      const tomorrow = moment().add(0, "days").set({
        hour: 6,
        minute: 59,
        second: 0,
        millisecond: 0,
      });
      let formattedEndDate = tomorrow.format("yyyy-MM-DD HH:mm");
      setEndDate(formattedEndDate);
    };

    fetchData();
  }, []);

  const handleLoadData = async () => {
    const p_param = {
      p_start_datetime: startDate,
      p_end_datetime: endDate,
      p_file_name: fileName,
      p_machine_name_like: machineName,
    };
    console.log(p_param);
    try {
      setIsLoading(true);
      const response = await loadApi(p_param, "api/txn/getPollDataFiles");
      console.log("Fetched Data:", response.data);
      console.log(response.data[0]);
      console.log(response.data[1]);
      if (response.data.length > 0) {
        let successFlag = response.data[0];
        if (successFlag === "Y") {
          console.log("success=Y");
          setRows(response.data[1]);
          setIsLoading(false);
          setWasDataFetchingSuccessful(false);
        } else {
          //setRows(null);
          setRows([]);
          setIsLoading(false);
          setWasDataFetchingSuccessful(true);
          const errorInfo = response.data[2];
          // Set the error message and display the dialog
          setErrorMessage(errorInfo);
          setErrorDialogVisible(true);
        }
        //setSearch(response.data);
      } else {
        //setRows(null);
        setRows([]);
        setIsLoading(false);
        setWasDataFetchingSuccessful(true);
      }

      //setRows(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const hideDialog = () => {
    setSubmitted(false);
    setRowDialog(false);
    setShowProductDialog(false);
    setErrorDialogVisible(false);
  };

  const showProduct = (row) => {
    setRow({ ...row });
    setShowProductDialog(true);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const getLoadingHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="row d-flex justify-content-center m-5">
          <div className="col-auto">
            <div className="card shadow-sm border-primary">
              <div className="card-header bg-primary text-white">
                <h2 className="mb-0">DXP Poll Data Files</h2>
              </div>
              <div className="card-body text-center">
                <h3>
                  <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                  The page is loading...
                </h3>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  const getLoadedHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="header-container">
        <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
         DXP Poll Data Files
              {/* <div className="header-container p-1">
              <style>
              {`
                input::placeholder {
                  font-size: 17px;
                }
              `}
            </style>
          <span className="p-input-icon-right w-full md:w-auto"> 
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto text-sm p-3"
          style={{ fontSize: '17px' }} 
        />
      </span>
          </div> */}
          </h2>
        </div>
      </React.Fragment>
    );
  };
  const getErrrorMsgContents = () => {
    return (
      <React.Fragment>
        <div class="row mt-5 justify-content-md-center">
          <div class="col-3">
            <div class="card border-secondary">
              <div class="card-header bg-dark text-white">
                DXP Poll Data Files table is Unavailable
              </div>
              <div class="card-body card-body-error rounded-bottom">
                <ul>
                  {loadingErrors.map((error) => (
                    <li>error</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  const getSearchControls = () => (
    <React.Fragment>
      <div className="col-auto form-group ps-2 pe-0 d-flex flex-column">
        <label className="form-label">Search</label>
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="input-outline"
        />
      </div>
    </React.Fragment>
  );

  const getFileNameControl = () => (
    <React.Fragment>
      <div className="col-auto form-group ps-2 pe-0 d-flex flex-column">
        <label className="form-label">File Name</label>
        <InputText
          type="text"
          id="FileName"
          name="FileName"
          onChange={(e) => setFileName(e.target.value)}
          placeholder="Enter file name"
          className="input-outline"
        />
      </div>
    </React.Fragment>
  );
  const getMachineNameControl = () => (
    <React.Fragment>
      <div className="col-auto form-group ps-2 pe-0 d-flex flex-column">
        <label className="form-label">Machine Name</label>
        <InputText
          type="text"
          id="MacName"
          name="MacName"
          onChange={(e) => setMachineName(e.target.value)}
          placeholder="Enter machine name"
          className="input-outline"
        />
      </div>
    </React.Fragment>
  );

  const getStartEndDateControls = () => (
    <React.Fragment>
      <div className="col-auto form-group ps-2 pe-0 d-flex flex-column">
        <label className="form-label">Start Date</label>
        <input
          type="datetime-local"
          id="StartDateTime"
          name="StartDateTime"
          value={startDate}
          onChange={handleStartDateChange}
          className="input-outline1"
        />
      </div>
      <div className="col-auto form-group ps-2 pe-0 d-flex flex-column">
        <label className="form-label">End Date</label>
        <input
          type="datetime-local"
          id="EndDateTime"
          name="EndDateTime"
          value={endDate}
          onChange={handleEndDateChange}
          className="input-outline1"
        />
      </div>
    </React.Fragment>
  );

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const getGoButtonControl = () => (
    <React.Fragment>
      <div className="col-auto d-flex align-items-center justify-content-center ps-2 pe-0" style={{paddingTop:'31px'}}>
        <button
          type="button"
          className="btn btn-primary load-button"
          id={"chartAreaId"}
          onClick={handleLoadData}
          disabled={isLoading}
        >
           <i
            className={
              isLoading ? "fa-duotone fa-fw fa-rotate fa-spin" : "fa-duotone fa-fw fa-rotate"
            }
          ></i>
          <span>{isLoading ? "Loading" : "Go"}</span>
        </button>
      </div>
    </React.Fragment>
  );

  const header = (
    <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
      {/* <span className="p-input-icon-right w-full md:w-auto">
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto"
        />
      </span> */}
      <div className="mt-1 md:mt-0 flex md:flex-1 justify-content-end">
        <Button
          icon="pi pi-upload"
          className="p-button-primary p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    </div>
  );
  const fileDateTemplate = (rowData) => {
    return moment(rowData.file_date).format("YYYY-MM-DD HH:mm:ss");
  };
  const localFileDeleteDateTemplate = (rowData) => {
    return moment(rowData.local_file_delete_date).format("YYYY-MM-DD HH:mm:ss");
  };
  const origFTPinDateTemplate = (rowData) => {
    return moment(rowData.orig_ftpin_date).format("YYYY-MM-DD HH:mm:ss");
  };
  const lastFTPinDateTemplate = (rowData) => {
    return moment(rowData.remote_file_size_bytes).format("YYYY-MM-DD HH:mm:ss");
  };

  const numOfDataRowsTemplate = (rowData) => {
    return numeral(rowData.num_of_data_rows).format("0,0");
  };
  const numOfDataColumnsTemplate = (rowData) => {
    return numeral(rowData.num_of_data_columns).format("0,0");
  };
  const numOfNonZeroDataTemplate = (rowData) => {
    return numeral(rowData.num_of_nonzero_data).format("0,0");
  };
  const numOfProdLineNoTemplate = (rowData) => {
    return numeral(rowData.production_line_number).format("0,0");
  };
  const numOfConcReqIdTemplate = (rowData) => {
    return numeral(rowData.conc_request_id).format("0,0");
  };
  const numOfLocalFileSizeTemplate = (rowData) => {
    return numeral(rowData.local_file_size_bytes).format("0,0");
  };
  const numFtpIdTemplate = (rowData) => {
    return numeral(rowData.remote_ip_address).format("0,0");
  };
  const numofRemoteFileSizeTemplate = (rowData) => {
    return numeral(rowData.remote_file_size_bytes).format("0,0");
  };

  const fileDate = moment(row.file_date).format("YYYY-MM-DD HH:mm:ss");
  const localFileDeleteDate = moment(row.local_file_delete_date).format(
    "YYYY-MM-DD HH:mm:ss"
  );
  const origFTPinDate = moment(row.orig_ftpin_date).format(
    "YYYY-MM-DD HH:mm:ss"
  );
  const lastFTPinDate = moment(row.last_ftpin_date).format(
    "YYYY-MM-DD HH:mm:ss"
  );

  const rownumOfDataRowsNumber = numeral(row.num_of_data_rows).format("0,0");
  const numOfDataColumnsNumber = numeral(row.num_of_data_columns).format("0,0");
  const numOfNonZeroDataNumber = numeral(row.num_of_nonzero_data).format("0,0");
  const numOfProdLineNoNumber = numeral(row.production_line_number).format(
    "0,0"
  );
  const numOfConcReqIdNumber = numeral(row.conc_request_id).format("0,0");
  const numOfLocalFileSizeNumber = numeral(row.local_file_size_bytes).format(
    "0,0"
  );
  const numFtpIdNumber = numeral(row.remote_ip_address).format("0,0");
  const numofRemoteFileSizeNumber = numeral(row.remote_file_size_bytes).format(
    "0,0"
  );

  const readBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-primary"
          onClick={() => showProduct(rowData)}
          tooltip="View"
          tooltipOptions={{ position: "bottom" }}
        />
      </React.Fragment>
    );
  };

  const formatFileDate = (rowData) => {
    return moment.utc(rowData.file_date).format("YYYY-MM-DD HH:mm:ss");
  };
  const formatDate = moment.utc(row.file_date).format("YYYY-MM-DD HH:mm:ss");

  return (
    <div>
      <Toast ref={toast} />
      <div className="col mx-2">
        {getLoadedHeaderContents()}
        {loadingErrors.length > 0 && getErrrorMsgContents()}
        <div className="row">
          <div className="col">
            <div className="row justify-content-center">
              {getSearchControls()}
              {getStartEndDateControls()}
              {getFileNameControl()}
              {getMachineNameControl()}
              {getGoButtonControl()}
            </div>
            {!wasDataFetchingSuccessful && (
              <div className="card">
                {/* <div className="text-3xl text-800 font-bold mb-4">DXP Poll Data Files</div> */}
                <DataTable
                  ref={dt}
                  value={rows}
                  scrollable
                  scrollHeight="flex"
                  selection={selectedRows}
                  onSelectionChange={(e) => setSelectedRows(e.value)}
                  dataKey="file_id"
                  className="custom-datatable-border"
                  tableStyle={{ minWidth: "50rem" }}
                  paginator
                  rows={pecaPageRows}
                  rowsPerPageOptions={pecaRowsPerPageOptions}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data"
                  globalFilter={globalFilter}
                  header={header}
                  emptyMessage="No data found"
                  stripedRows
                  showGridlines
                >
                  <Column
                    body={readBodyTemplate}
                    exportable={false}
                    style={{ width: "0.75rem" }}
                  ></Column>
                  <Column
                    field="file_id"
                    header="File Id"
                    sortable
                    hidden="true"
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    field="file_name"
                    header="File Name"
                    sortable
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    field="file_date"
                    header="Date"
                    sortable
                    body={fileDateTemplate}
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    field="num_of_data_rows"
                    header="#Of Data Rows"
                    sortable
                    body={numOfDataRowsTemplate}
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    field="num_of_data_columns"
                    header="#Of Columns"
                    sortable
                    body={numOfDataColumnsTemplate}
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    field="num_of_nonzero_data"
                    header="#Of Non-zero data"
                    sortable
                    body={numOfNonZeroDataTemplate}
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>

                  <Column
                    field="ora_machine_id"
                    header="Ora Machine Id"
                    sortable
                    hidden="true"
                    exportable={false}
                  ></Column>
                  <Column
                    field="machine_name"
                    header="Machine Name"
                    sortable
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    field="dxp_file_prefix"
                    header="File Prefix"
                    sortable
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    field="process_id"
                    header="Process Id"
                    sortable
                    hidden="true"
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    field="process_name"
                    header="Process"
                    sortable
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>

                  <Column
                    field="production_line_number"
                    header="Line#"
                    sortable
                    body={numOfProdLineNoTemplate}
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    field="ora_processed_flag"
                    header="Ora Processed ?"
                    sortable
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    field="ora_error_message"
                    header="Ora Errors"
                    sortable
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    field="local_file_size_bytes"
                    header="Local File Size(B)"
                    sortable
                    body={numOfLocalFileSizeTemplate}
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    field="local_file_delete_date"
                    header="Local File Deleted Date"
                    sortable
                    body={localFileDeleteDateTemplate}
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    field="conc_request_id"
                    header="Conc Req Id"
                    sortable
                    body={numOfConcReqIdTemplate}
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    field="remote_ip_address"
                    header="FTP IP"
                    sortable
                    body={numFtpIdTemplate}
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    field="remote_user_name"
                    header="FTP User"
                    sortable
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    field="remote_dir_name"
                    header="Remote Dir"
                    sortable
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    field="orig_ftpin_date"
                    header="Org Ftp date"
                    sortable
                    body={origFTPinDateTemplate}
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    field="last_ftpin_date"
                    header="Last Ftp date"
                    sortable
                    body={lastFTPinDateTemplate}
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    field="remote_file_size_bytes"
                    header="Remote File Size(B)"
                    sortable
                    body={numofRemoteFileSizeTemplate}
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                </DataTable>
              </div>
            )}
          </div>
        </div>

        <Dialog
          visible={showproductDialog}
          style={{ width: "1400px" }}
          header="DXP Poll Data Files"
          modal
          className="p-fluid"
          onHide={hideDialog}
        >
          {/* <label htmlFor="file_id">File Id</label>
          <div className="box">{row.file_id}</div>

          <label htmlFor="file_date">Date</label>
          <div className="box">{formatDate}</div>

          <label htmlFor="file_name">File Name</label>
          <div className="box">{row.file_name}</div>

          <label htmlFor="machine_name">Machine Name</label>
          <div className="box">{row.machine_name}</div>

          <label htmlFor="dxp_file_prefix">File Prefix</label>
          <div className="box">{row.dxp_file_prefix}</div>

          <label htmlFor="process_name">Process</label>
          <div className="box">{row.process_name}</div>

          <label htmlFor="production_line_number">Ln#</label>
          <div className="box">{row.production_line_number}</div>

          <label htmlFor="ora_processed_flag">OraProcessed?</label>
          <div className="box">{row.ora_processed_flag}</div>

          <label htmlFor="name_ruleora_error_message">Ora Errors</label>
          <div className="box">{row.ora_error_message}</div>

          <label htmlFor="num_of_data_rows">#DataRows</label>
          <div className="box">{row.num_of_data_rows}</div>

          <label htmlFor="remote_ip_address">FTP IP</label>
          <div className="box">{row.remote_ip_address}</div>

          <label htmlFor="orig_ftpin_date">First ftp date</label>
          <div className="box">{row.orig_ftpin_date}</div>

          <label htmlFor="last_ftpin_date">Last ftp date</label>
          <div className="box">{row.last_ftpin_date}</div>

          <label htmlFor="remote_file_size_bytes">Remote File Size(B)</label>
          <div className="box">{row.remote_file_size_bytes}</div>

          <label htmlFor="local_file_size_bytes">Local File Size(B)</label>
          <div className="box">{row.local_file_size_bytes}</div>

          <label htmlFor="local_file_delete_date">Local File Deleted on</label>
          <div className="box">{row.local_file_delete_date}</div>

          <label htmlFor="remote_user_name">FTP User</label>
          <div className="box">{row.remote_user_name}</div>

          <label htmlFor="remote_dir_name">Remote Dir</label>
          <div className="box">{row.remote_dir_name}</div> */}

          <div className="grid-container-polldatafiles">
            <div className="grid-item">
              <h3>File Info</h3>
              <hr />
              <div className="info-row">
                <label htmlFor="file_name">File Name</label>
                <div className="box1">{row.file_name}</div>
              </div>
              <div className="info-row">
                <label htmlFor="file_date">Date</label>
                <div className="box1">{formatDate}</div>
              </div>
              <div className="info-row">
                <label htmlFor="num_of_data_rows"># Of Data Rows</label>
                <div className="box1">{rownumOfDataRowsNumber}</div>
              </div>
              <div className="info-row">
                <label htmlFor="num_of_data_columns">#Of Columns</label>
                <div className="box1">{numOfDataColumnsNumber}</div>
              </div>
              <div className="info-row">
                <label htmlFor="num_of_nonzero_data">#Of Non-zero data</label>
                <div className="box1">{numOfNonZeroDataNumber}</div>
              </div>
            </div>
            <div className="grid-item">
              <h3>Machine Info</h3>
              <hr />
              <div className="info-row">
                <label htmlFor="machine_name">Machine Name</label>
                <div className="box1" style={{ textAlign: "right" }}>
                  {row.machine_name}
                </div>
              </div>
              <div className="info-row">
                <label htmlFor="dxp_file_prefix">File Prefix</label>
                <div className="box1" style={{ textAlign: "right" }}>
                  {row.dxp_file_prefix}
                </div>
              </div>
              <div className="info-row">
                <label htmlFor="process_name">Process</label>
                <div className="box1" style={{ textAlign: "right" }}>
                  {row.process_name}
                </div>
              </div>
              <div className="info-row">
                <label htmlFor="production_line_number">Ln#</label>
                <div className="box1" style={{ textAlign: "right" }}>
                  {numOfProdLineNoNumber}
                </div>
              </div>
            </div>
            <div className="grid-item">
              <h3>Oracle Interface Info</h3>
              <hr />
              <div className="info-row">
                <label htmlFor="ora_processed_flag">Ora Processed ?</label>
                <div className="box1" style={{ textAlign: "right" }}>
                  {row.ora_processed_flag}
                </div>
              </div>
              <div className="info-row">
                <label htmlFor="ora_error_message">Ora Errors</label>
                <div className="box1" style={{ textAlign: "right" }}>
                  {row.ora_error_message}
                </div>
              </div>
              <div className="info-row">
                <label htmlFor="local_file_size_bytes">
                  Local File Size (B)
                </label>
                <div className="box1" style={{ textAlign: "right" }}>
                  {numOfLocalFileSizeNumber}
                </div>
              </div>
              <div className="info-row">
                <label htmlFor="local_file_delete_date">
                  Local File Delete Date
                </label>
                <div className="box1" style={{ textAlign: "right" }}>
                  {row.local_file_delete_date}
                </div>
              </div>
              <div className="info-row">
                <label htmlFor="conc_request_id">Conc Req Id</label>
                <div className="box1" style={{ textAlign: "right" }}>
                  {numOfConcReqIdNumber}
                </div>
              </div>
            </div>
            <div className="grid-item">
              <h3>Remote Server Info</h3>
              <hr />

              <div className="info-row">
                <label htmlFor="remote_ip_address">FTP IP</label>
                <div className="box1">{numFtpIdNumber}</div>
              </div>
              <div className="info-row">
                <label htmlFor="remote_user_name">FTP User</label>
                <div className="box1">{row.remote_user_name}</div>
              </div>
              <div className="info-row">
                <label htmlFor="remote_dir_name">Remote Dir</label>
                <div className="box1">{row.remote_dir_name}</div>
              </div>
              <div className="info-row">
                <label htmlFor="orig_ftpin_date">Orig Ftp Date</label>
                <div className="box1">{row.orig_ftpin_date}</div>
              </div>
              <div className="info-row">
                <label htmlFor="last_ftpin_date">Last Ftp Date</label>
                <div className="box1">{row.last_ftpin_date}</div>
              </div>
              <div className="info-row">
                <label htmlFor="remote_file_size_bytes">
                  Remote File Size (B)
                </label>
                <div className="box1">{numofRemoteFileSizeNumber}</div>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog
          header="API Error"
          visible={errorDialogVisible}
          style={{ width: "400px" }}
          onHide={hideDialog}
          footer={
            <Button
              label="OK"
              onClick={hideDialog}
              className="p-button-primary p-button-rounded"
            />
          }
        >
          <p>{errorMessage}</p>
        </Dialog>
      </div>
    </div>
  );
};

export default PollDataFiles;
