import React, { useState } from "react";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "assets/scss/dashboard.scss";
import "assets/css/charts_form_controls.css";
import "assets/css/import.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { loadApi } from "services/appApi";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import { pecaPageRows, pecaRowsPerPageOptions } from "pages/common/envPECA";

const OPCServerImportData = () =>{
    const [file, setFile] = useState(null);
    const [csvData, setCsvData] =  useState([]);
    const [showCard, setShowCard] = useState(true);
    const [fileName, setFileName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [importing, setImporting] = useState(false);
    const [dialogVisible, setDialogVisbile] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogType, setDialogType] = useState("");
    const navigate = useNavigate();

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        setFileName(selectedFile.name);
    }

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        setFile(droppedFile);
        setFileName(droppedFile.name)
    }

    const handleDragOver = (e) => {
        e.preventDefault();
    }

    const handleDelete = () => {
        setFile(null);
        setCsvData([]);
    } 

    // const handleNext = () => {
    //     if(file) {
    //         setIsLoading(true);
    //         const reader = new FileReader();
    //         reader.onload = () => {
    //             const fileContent = reader.result;
    //             const lines = fileContent.split('\n');
    //             let rowData = [];
    //             lines.forEach((line, index) => {
    //                 line =  line.trim();
    //                 if(!line && line !== '') return;
    //                 rowData.push ({
    //                     filename:fileName,
    //                     rowNumber: index+1,
    //                     data: line || '',
    //                 })
    //             })
    //             setCsvData(rowData);
    //             setShowCard(false);
    //             setIsLoading(false);
    //         }
    //         reader.readAsText(file);
    //     }
    // }

    const handleNext = () => {
        if(file) {
            setIsLoading(true);
            const reader = new FileReader();
            reader.onload = () => {
                const fileContent = reader.result;
                const lines = fileContent.split("\n").map((line) => line.trim()).filter((line) => line !== "");
                setCsvData(lines);
                setShowCard(false);
                setIsLoading(false);
            }
            reader.readAsText(file);
        }
    }

    // const handleImport = async () => {
    //     setImporting(true);
    //     for (const row of csvData) {
    //         try{
    //             const p_param = {p_file_name: row.filename, p_row_number: row.rowNumber, p_data_string: row.data}
    //             console.log("p_param: ",p_param);
    //             setCurrentRow(row.rowNumber)

    //             const response = await loadApi(p_param,"/api/setup/opcIntfc/insRow")
    //             console.log("Response data: ",response.data);
    //             console.log("Response: ",response);
    //             let successFlag =
    //             response.data[0]; /** This contains value of Y or N */
    //           if (successFlag === "N") {
    //                 setDialogMessage(`Error: ${response.data[2]}`)
    //           }
    //             if (response.status !== 200) {
    //                 throw new Error (`Failed to insert row ${row.rowNumber}`);
    //             }
    //         } catch (error) {
    //             setDialogMessage(`Error: ${error.message}`);
    //             setDialogType("error");
    //             setDialogVisbile(true);
    //             setImporting(false);
    //             setCurrentRow(null);
    //             return;
    //         }
    //     }
    //     setDialogMessage("Data inserted successfully");
    //     setDialogType("success");
    //     setDialogVisbile(true);
    //     setImporting(false);
    //     setCurrentRow(null);
    // }

    const handleImport = async () => {
        setImporting(true);
                try{
                const p_param = {p_file_name: fileName, p_arr_data_string: csvData}
                console.log("p_param: ",p_param);

                const response = await loadApi(p_param,"/api/setup/opcIntfc/loadOpcSetups")
                console.log("Response data: ",response.data);
                console.log("Response: ",response);
                let successFlag = response.data[0]; /** This contains value of Y or N */
                if  (successFlag === 'Y'){
                    setDialogMessage("Data inserted successfully");
                    setDialogType("success");
                    setDialogVisbile(true);
                    setImporting(false);
                }
                if (successFlag === "N") {
                    setDialogMessage(`Error: ${response.data[2]}`)
                    setDialogType("error");
                    setDialogVisbile(true);
                    setImporting(false);
                }
                if (response.status !== 200) {
                    throw new Error ("Failed to insert data");
                }
            } catch (error) {
                setDialogMessage(`Error: ${error.message}`);
                setDialogType("error");
                setDialogVisbile(true);
                setImporting(false);
                return;
            }
    }

      const header = (
        <div className="action-buttons">
            <button className="back-button" onClick={() => setShowCard(true)}>
                <i className="fas fa-arrow-left"></i> Back
            </button>
            <button className="import-button" onClick={handleImport} disabled={importing}>
                {importing ? (
                    <>
                        <i className="fas fa-spinner fa-spin"></i> Importing...
                    </>
                ) : (
                    <>
                        <i className="fas fa-file-import"></i> Import
                    </>
                )}
            </button>
        </div>
      );
    
   
    return (
        <div className="header-container">
            <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">Import Data</h2>
            {showCard && (
            <div className="cards-container">
                <div className="cards">
                    <h3>File to Upload</h3>
                    <div className="drop-areas" onDrop={handleDrop} onDragOver={handleDragOver}>
                        {file ? (
                            <div className="file-info">
                            <p>{file.name}</p> 
                            <button className="delete-button" onClick={handleDelete}>
                                <i className="fas fa-trash-alt"></i>
                            </button>
                        </div>
                        ):(
                            <p>Drag and drop file or select file manually</p>
                        )}
                    </div>
                    <div className="upload-btn-container">
                        <input
                            type="file"
                            id="csv-input"
                            accept=".csv"
                            onChange={handleFileChange}
                            style={{display:'none'}}
                        />
                        <button className="button" onClick={() => document.getElementById('csv-input').click()}>
                            Select File
                        </button>
                    </div>   
                </div>
                <div className="next-button-container">
                    <button className="next-button" onClick={handleNext} disabled = {!file || isLoading}>
                        {isLoading ? (
                            <>
                                <i className="fas fa-spinner fa-spin"></i> Loading...
                            </>
                        ) : (
                            <>
                                Next <i className="fas fa-arrow-right right"></i>
                            </>
                        )}
                    </button>
                </div>
            </div>
            )}
             <div className="col mx-2">
            {!showCard && (
                <div className="table-container">
                    <div className="card">
                    <DataTable value={csvData.map((line, index) => ({
                        rowNumber: index + 1,
                        data: line,
                        filename: fileName,
                    }))} 
                    responsiveLayout="scroll"
                    scrollable scrollHeight="flex"
                    className="custom-datatable-border"
                    tableStyle={{ minWidth: '50rem' }}
                    paginator
                    rows={pecaPageRows}
                    rowsPerPageOptions={pecaRowsPerPageOptions}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data"
                    emptyMessage="No data found"
                    header = {header}
                    stripedRows
                    showGridlines>
                        <Column field="filename" header="File Name" />
                        <Column field="rowNumber" header="Row Number" bodyStyle={{textAlign: "right"}} />
                        <Column field="data" header="Process OPC Data" />
                    </DataTable>
                    </div>
                </div>
            )}
            <Dialog
                header={dialogType === "success" ? "Success" : "Error"}
                visible={dialogVisible}
                style={{
                    width:"350px",
                    backgroundColor: dialogType === "success" ? "#d4edda" : "#f8d7da",
                }}
                onHide={()=>setDialogVisbile(false)}
                >
                    <p>{dialogMessage}</p>
                    <div>
                        {dialogType === "success" ? (
                            <button className="button" onClick={() => navigate("/AdminPages/OPCServerSetups/OPCServerProcessData")}>Next{" "}<i className="fas fa-arrow-right right"></i></button>
                        ) : (
                            <button className="button" onClick={() => setDialogVisbile(false)}>OK</button>
                        )}
                    </div>
                </Dialog>
            </div>
        </div>
    )
}
 export default OPCServerImportData;