import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "assets/scss/dashboard.scss";
import "assets/css/charts_form_controls.css";
import React, { useState, useRef, useLayoutEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "assets/css/grid_table.css";
import "assets/css/import.css";
import { getDataApi, saveApi, loadApi } from "services/appApi";
import { pecaPageRows, pecaRowsPerPageOptions } from "pages/common/envPECA";

const ExtractOpcDataLogger  = () => {
  let emptyRow = {
    machine_id: null,
    machine_name: "",
    machine_description: "",
    opc_machine_name: "",
    opc_ip_address: "",
    dxp_opc_comm_name: "",
    dxp_file_prefix: "",
    production_line_number: null,
    process_id: null,
    process_name: ""
  };

  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [successDialog, setSuccessDialog] = useState(false);
  const [currentView, setCurrentView] = useState(null);
  const [viewData, setViewData] = useState([]);
  const [viewColumns, setViewColumns] = useState([])
  const [globalFilter, setGlobalFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(false);
  const [message, setMessage] = useState(""); 
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploading, setUploading] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  

  // Fetch Machines when the component mounts
  useLayoutEffect(() => {
    const fetchRows = async () => {
      try {
        setIsLoading(true);
        const response = await getDataApi("/api/setup/machines/selRows");
        console.log("Fetched Data:", response.data);
        console.log(response.data[0]);
        console.log(response.data[1]);
        if (response.data.length > 0) {
          let successFlag = response.data[0];
          if (successFlag === "Y") {
            console.log("success=Y");
            setRows(response.data[1]);
            setIsLoading(false);
          } else {
            //setRows(null);
            setRows([]);
            setIsLoading(false);
            const errorInfo = response.data[2];

            // Set the error message and display the dialog
            setErrorMessage(errorInfo);
            setErrorDialogVisible(true);
          }
          //setSearch(response.data);
        } else {
          //setRows(null);
          setRows([]);
          setIsLoading(false);
        }

        //setRows(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchRows();
  }, []);
 
const handleUpload = async () => {
        const selectedMachineIds = selectedRows.map(machine => machine.machine_id);
        setUploading(true);
                try{
                const p_param = {p_arr_machine_ids: selectedMachineIds}
                console.log("p_param: ",p_param);

                const response = await loadApi(p_param,"/api/setup/expOpcDlogData/buildOpcDLogData")
                console.log("Response data: ",response.data);
                console.log("Response: ",response);
                let successFlag = response.data[0]; /** This contains value of Y or N */
                if  (successFlag === 'Y'){
                    setSuccessDialog(true);
                    const message = response.data[1];
                    setMessage(message);
                    setUploading(false);
                }
                if (successFlag === "N") {
                  const errorInfo = response.data[1];
                  setErrorMessage(errorInfo);
                  setErrorDialogVisible(true);
                }
                if (response.status !== 200) {
                    throw new Error ("Failed to Upload data");
                }
            } catch (error) {
                setErrorMessage(error.message);
                setUploading(false);
                return;
            }
    }

    const fetchData = async (apiEndPoint, type) => {
      try {
        setIsLoading1(true);
        const response = await loadApi("",apiEndPoint);
        console.log("Fetched Data:", response.data);
        console.log(response.data[0]);
        console.log(response.data[1]);
        if (response.data.length > 0) {
          let successFlag = response.data[0];
          if (successFlag === "Y") {
            console.log("success=Y");
            if(Array.isArray(response.data) && response.data.length>1){
              setViewData(response.data[1]);
            }
            console.log("View Data: ",viewData);
            setIsLoading1(false);
            if(type === 'ShowOpcData') {
              setViewColumns([
                {field : 'export_data', header: 'CSV Data'}
              ])
            } else if (type === 'ShowDLogData'){
              setViewColumns([
                {field : 'export_data', header: 'CSV Data'}
              ])
            }
            setCurrentView(type);
            setSuccessDialog(false);
          } else {
            //setRows(null);
            setViewData([]);
            setIsLoading1(false);
            const errorInfo = response.data[2];

            // Set the error message and display the dialog
            setErrorMessage(errorInfo);
            setErrorDialogVisible(true);
          }
          //setSearch(response.data);
        } else {
          //setRows(null);
          setViewData([]);
          setIsLoading(false);
        }

        //setRows(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

  const hideDialog = () => {
    setErrorDialogVisible(false);
  };

console.log("View Data: ",viewData);
console.log("Current View: ",currentView);

const exportCSV = () => {
  dt.current.exportCSV();
};

const getLoadingHeaderContents = () => {
  return (
    <React.Fragment>
      <div className="row d-flex justify-content-center m-5">
        <div className="col-auto">
          <div className="card shadow-sm border-primary">
            <div className="card-header bg-primary text-white">
              <h2 className="mb-0">
              Extract OPC/DataLogger data for Import
              </h2>
            </div>
            <div className="card-body text-center">
              <h3>
                <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                The page is loading...
              </h3>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}
const getLoadedHeaderContents = () => {
                return (
                  <React.Fragment>
                    <div className="header-container">
                      <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
                      Extract OPC/DataLogger data for Import
                      </h2>
                      <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
                        <div className="header-container p-1">
                            <style>
                            {`
                              input::placeholder {
                                font-size: 17px; 
                              }
                            `}
                          </style>
                        <span className="p-input-icon-right w-full md:w-auto"> 
                      <InputText
                        type="search"
                        onInput={(e) => setGlobalFilter(e.target.value)}
                        placeholder="Search..."
                        className="w-full lg:w-auto text-sm p-3"
                        style={{ fontSize: '17px' }} 
                      />
                    </span>
                        </div>
                      </h2>
                    </div>
                  </React.Fragment>
                );
              }

  const header = (
    <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
      {/* <span className="p-input-icon-right w-full md:w-auto">
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto"
        />
      </span> */}
      <div className="mt-1 md:mt-0 flex md:flex-1 justify-content-end">
      <button className="import-button" onClick={handleUpload} disabled={uploading}>
            {uploading ? (
              <>
                <i className="fas fa-spinner fa-spin"></i> Uploading...
              </>
            ) : (
              <>
                <i className="pi pi-upload"></i> Upload
              </>
            )}
      </button>
      </div>
    </div>
  );
  const headers = (
    <div style={{display:'flex', justifyContent:'space-between', padding: '0 10px', alignItems:'center', marginBottom:'10px', marginTop: '10px'}}>
      <button className="back-button" onClick={() => {setCurrentView(null);
          setSuccessDialog(true);
      }}>
        <i className="fas fa-arrow-left"></i> Back
      </button>
      <h3 className="normal-font" style={{textAlign:'center'}}>{currentView === 'ShowOpcData' ? 'Opc Data' : 'Data Logger Data'}</h3>
      <button className="back-button" onClick={exportCSV}>
        <i className="pi pi-upload"></i> Export
      </button>
    </div>

    
  );

  return (
    <div>
      <Toast ref={toast} />
      <div className="col mx-2">
      {isLoading ? getLoadingHeaderContents() : getLoadedHeaderContents()}
      {!isLoading && currentView === null && (
      <div className="card">
        {/* <div className="text-3xl text-800 font-bold mb-4">Extract OPC/DataLogger data for Import</div> */}
        <DataTable
          value={rows}
          scrollable scrollHeight="flex"     
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="machine_id"
          className="custom-datatable-border"
          tableStyle={{ minWidth: '50rem' }}
          paginator
          rows={pecaPageRows}
          rowsPerPageOptions={pecaRowsPerPageOptions}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data"
          globalFilter={globalFilter}
          header={header}
          emptyMessage="No data found"
          stripedRows
          showGridlines
        >

          <Column selectionMode="multiple" headerStyle={{width:'3em'}} exportable={false} ></Column>
          <Column field="machine_id" header="Machine Id" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="machine_name" header="Machine Name" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="opc_machine_name" header="OPC Device Name" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="opc_ip_address" header="IP address" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="dxp_opc_comm_name" header="DXP/OPC Communication Name" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="dxp_file_prefix" header="DXP File Prefix" sortable style={{whiteSpace: 'nowrap' }}></Column>   
          <Column field="production_line_number" header="Production Line Number" sortable style={{whiteSpace: 'nowrap', textAlign: 'right',width:'12px' }}></Column>
          <Column field="process_id" header="Process Id" sortable style={{whiteSpace: 'nowrap', textAlign: 'right'  }}></Column>
          <Column field="process_name" header="Process Name" sortable style={{whiteSpace: 'nowrap' }}></Column>
        
        </DataTable>
      </div>
      )}

      <Dialog header="Upload Success" visible = {successDialog} style={{width:'600px'}} modal onHide={() => setSuccessDialog(false)}>
          <p style={{textAlign:'center'}}>{message}</p>
          <div style={{display:'flex', justifyContent:'space-around', marginTop:'1rem'}}>
            <button className="back-button" onClick={() => {fetchData('/api/setup/expOpcDlogData/selOpcData','ShowOpcData')
              setCurrentView('ShowOpcData');
            }} disabled={isLoading1}>
            <i
              className={
                isLoading1 && currentView ==="ShowOpcData" && "fas fa-spinner fa-spin"
              }
            ></i>Show Opc Data
            </button>
            <button  className="back-button" onClick={() =>{ fetchData('/api/setup/expOpcDlogData/selDLogData','ShowDLogData');
              setCurrentView('ShowDLogData');
            }} disabled={isLoading1}>
            <i
              className={
                isLoading1 && currentView === "ShowDLogData" && "fas fa-spinner fa-spin"
              }
            ></i>Show DLog Data
            </button>
          </div>
      </Dialog>

      {!isLoading1 && currentView && viewData.length > 0 && (
        <>
        <div className="card">
        {/* <div className="text-3xl text-800 font-bold mb-4">{currentView === 'ShowOpcData' ? 'Opc Data' : 'Data Logger Data'}</div> */}
        <DataTable
          ref={dt}
          value={viewData}
          scrollable scrollHeight="flex"     
          dataKey="csv_data"
          className="custom-datatable-border"
          tableStyle={{ minWidth: '50rem' }}
          paginator
          rows={pecaPageRows}
          rowsPerPageOptions={pecaRowsPerPageOptions}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data"
          globalFilter={globalFilter}
          header={headers}
          emptyMessage="No data found"
          stripedRows
          showGridlines
        >
        
        {viewColumns.map(col => (
          <Column key={col.field} field={col.field} header={col.header} headerStyle={{textAlign:'center'}}></Column>
        ))}
          
        </DataTable>
      </div>
        </>
      )}

      
            <Dialog 
                header="API Error" 
                visible={errorDialogVisible} 
                style={{ width: '400px' }} 
                onHide={hideDialog} 
                footer={
                    <Button label="OK" onClick={hideDialog} className="p-button-primary p-button-rounded" />
                }
            >    
             <p>

                    {errorMessage}
                </p>
            </Dialog>
            </div>
    </div>
  );
};

export default ExtractOpcDataLogger;
