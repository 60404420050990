import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "assets/scss/dashboard.scss";
import "assets/css/charts_form_controls.css";
import "assets/css/grid_table.css";
import numeral from "numeral";
import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import "./machines.css";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import moment from "moment";
import { getDataApi, saveApi, loadApi } from "services/appApi";
import { pecaPageRows, pecaRowsPerPageOptions } from "pages/common/envPECA";

const MachinesPG = () => {
  let emptyRow = {
    machine_id: null,
    machine_name: "",
    machine_description: "",
    thereotical_capacity: 0,
    created_by: null,
    process_name: "",
    production_line_number: null,
    dxp_file_prefix: "",
    dxp_opc_comm_name: "",
    creation_date: "",
    last_updated_by: null,
    last_update_date: "",
    last_update_login: null,
    prod_enabled_flag_disp: "Yes",
    start_date: "",
    end_date: "",
    production_speed: 0,
    target_efficiency: 0,
    wait_time_sort_order: 0,
    prod_count_flag: "",
    machine_code: "",
    opc_machine_name: "",
  };

  /**** 31-May-2024 Velmani P. Following changes are made to template
   * 	a. Use a generic name "row" instead of "product".  Following are the name changed from template
   * 	   a1. products ==> rows
   * 		[products, setProducts] ==> [products, setRows]
   * 		[deleteProductsDialog, setDeleteProductsDialog] ==> [deleteRowsDialog, setDeleteRowsDialog]
   * 		[selectedProducts, setSelectedProducts] ==> [selectedRows, setSelectedRows]
   * 	   a2. product  ==> row
   *		[productDialog, setProductDialog] ==> [productDialog, setRowDialog]
   *		[deleteProductDialog, setDeleteProductDialog] ==> [deleteRowDialog, setDeleteRowDialog]
   *		[product, setProduct] ==> [product, setRow]
   * ******/
  const [rows, setRows] = useState([]);
  const [rowDialog, setRowDialog] = useState(false);
  const [deleteRowDialog, setDeleteRowDialog] = useState(false);
  const [row, setRow] = useState(emptyRow);
  const [selectedRows, setSelectedRows] = useState(null);
  const [showproductDialog, setShowProductDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [globalFilter1, setGlobalFilter1] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [expandedRowId, setExpandedRowId] = useState([]);
  const [machines, setMachines] = useState({});
  const toast = useRef(null);
  const dt = useRef(null);
  const dt1 = useRef(null);

  // Fetch Machines when the component mounts
  useLayoutEffect(() => {
    const fetchRows = async () => {
      try {
        setIsLoading(true);
        const response = await getDataApi("/api/setup/machines/selRows");
        console.log(response);
        console.log("Fetched machines:", response.data);
        console.log(response.data[0]);
        console.log(response.data[1]);
        if (response.data.length > 0) {
          let successFlag = response.data[0];
          if (successFlag === "Y") {
            console.log("success=Y");
            setRows(response.data[1]);
            setIsLoading(false);
          } else {
            //setRows(null);
            setRows([]);
            setIsLoading(false);
            const errorInfo = response.data[2];

            // Set the error message and display the dialog
            setErrorMessage(errorInfo);
            setErrorDialogVisible(true);
          }
          //setSearch(response.data);
        } else {
          //setRows(null);
          setRows([]);
          setIsLoading(false);
        }

        //setRows(response.data);
      } catch (error) {
        console.error("Error fetching machines:", error);
      }
    };

    fetchRows();
  }, []);
  const showProduct = (row) => {
    setRow({ ...row });
    setShowProductDialog(true);
  };
  const openNew = () => {
    setRow(emptyRow);
    setSubmitted(false);
    setRowDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setRowDialog(false);
    setShowProductDialog(false);
    setErrorDialogVisible(false);
  };
  const hideDeleteRowDialog = () => {
    setDeleteRowDialog(false);
  };

  const formatDateColumn = (dateValue) => {
    return dateValue ? moment(dateValue).format("YYYY-MM-DD") : null;
  };

  const startDateBodyTemplate = (rowData) => {
    return formatDateColumn(rowData.start_date);
  };

  const endDateBodyTemplate = (rowData) => {
    return formatDateColumn(rowData.end_date);
  };

  /***
        const formatDateFields = (row, isNew) => {
            const formatDate = (date) => date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null;
    
            return {
                ...row,
                creation_date: isNew ? formatDate(new Date()) : formatDate(row.creation_date),
                last_update_date: formatDate(new Date()),
                start_date: formatDate(row.start_date),
                end_date: formatDate(row.end_date)
            };
        };
         */

  const saveRow = async () => {
    setSubmitted(true);

    if (row.machine_name.trim()) {
      //      const isNew = !row.machine_id;
      //     const formattedRow = formatDateFields(row, isNew);

      let _rows = [...rows];
      let _row = { ...row };

      try {
        if (row.machine_id) {
          /*** Update Row */
          const response = await saveApi(row, "/api/setup/machines/updRow");
          //await axios.put(`http://localhost:5000/api/machines/${row.machine_id}`, formattedRow);
          //             const updatedRows = rows.map((p) => (p.machine_id === row.machine_id ? formattedRow : p));
          //             setRows(updatedRows);
          console.log("updating");
          console.log(response.data);
          let successFlag = response.data[0];
          if (successFlag === "Y") {
            const index = findIndexById(row.machine_id);
            _rows[index] = _row;
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Machine Updated",
              life: 3000,
              width: "600px",
            });
          } else {
            let err_msg = response.data[1];
            toast.current.show({
              severity: "error",
              summary: err_msg,
              detail: "Machine Not Updated",
              life: 3000,
              width: "600px",
            });
          }
        } else {
          /*** Insert Row */
          const response = await saveApi(row, "/api/setup/machines/insRow");
          //const response = await axios.post('http://localhost:5000/api/machines', formattedRow);
          //       formattedRow.machine_id = response.data.id;
          //     setRows([...rows, formattedRow]);
          console.log("saving");
          console.log("Response data: ", response.data);
          console.log("Response: ", response);
          let successFlag =
            response.data[0]; /** This contains value of Y or N */
          if (successFlag === "Y") {
            _row.machine_id =
              response.data[1]; /** It contains newly created Id */
            _rows.push(_row);
            toast.current.show({
              severity: "success",
              summary: (
                <div style={{ marginright: "200px", width: "600px" }}>
                  err_msg
                </div>
              ),
              detail: <div style={{ width: "600px" }}>err_msg</div>,
              life: 3000,
              width: "600px",
            });
          } else {
            let err_msg = response.data[2];
            toast.current.show({
              severity: "error",
              summary: <div style={{ width: "600px" }}>{err_msg}</div>,
              detail: <div style={{ width: "600px" }}>{err_msg}</div>,
              life: 3000,
              width: "600px",
            });
          }
        }

        setRows(_rows);
        setRowDialog(false);
        setRow(emptyRow);
      } catch (error) {
        console.error("Error saving machine:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Machine Save Failed",
          life: 3000,
          width: "600px",
        });
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please fill in all required fields",
        life: 3000,
        width: "600px",
      });
    }
  };

  const editRow = (row) => {
    setRow({ ...row });
    setRowDialog(true);
  };

  const confirmDeleteRow = (row) => {
    setRow(row);
    setDeleteRowDialog(true);
  };

  const deleteRow = async () => {
    try {
      const response = await saveApi(row, "api/setup/machines/delRow");
      let successFlag = response.data[0];
      if (successFlag === "Y") {
        let _rows = rows.filter((val) => val.machine_id !== row.machine_id);
        setRows(_rows);
        setDeleteRowDialog(false);
        setRow(emptyRow);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Machine Deleted",
          life: 3000,
          width: "600px",
        });
      } else {
        let err_msg = response.data[1];
        toast.current.show({
          severity: "error",
          summary: err_msg,
          detail: "Machine Not Deleted",
          life: 3000,
          width: "600px",
        });
      }
    } catch (error) {
      console.error("Error deleting machine:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Machine Deletion Failed",
        life: 3000,
        width: "600px",
      });
    }
  };

  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].machine_id === id) {
        index = i;
        break;
      }
    }
    return index;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const getLoadingHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="row d-flex justify-content-center m-5">
          <div className="col-auto">
            <div className="card shadow-sm border-primary">
              <div className="card-header bg-primary text-white">
                <h2 className="mb-0">Setup Machines</h2>
              </div>
              <div className="card-body text-center">
                <h3>
                  <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                  The page is loading...
                </h3>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const getLoadedHeaderContents = () => {
                  return (
                    <React.Fragment>
                      <div className="header-container">
                        <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
                        Setup Machines
                        </h2>
                        <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
                          <div className="header-container p-1">
                              <style>
                              {`
                                input::placeholder {
                                  font-size: 17px; 
                                }
                              `}
                            </style>
                          <span className="p-input-icon-right w-full md:w-auto"> 
                        <InputText
                          type="search"
                          onInput={(e) => setGlobalFilter(e.target.value)}
                          placeholder="Search..."
                          className="w-full lg:w-auto text-sm p-3"
                          style={{ fontSize: '17px' }} 
                        />
                      </span>
                          </div>
                        </h2>
                      </div>
                    </React.Fragment>
                  );
                }

  const deleteRowDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteRowDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteRow}
      />
    </React.Fragment>
  );

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _row = { ...row };
    _row[`${name}`] = val;

    setRow(_row);
  };

  /***
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const headerDontUse = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Machines</h5>
            {/* <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span> * /}
        </div>
    );
    */

  const header = (
    <div
      className="table-header"
      style={{
        display: "flex",
        justifyContent: "space-between",
        height: "3rem",
        alignContent: "center",
      }}
    >
      <div
        style={{
          width: window.innerWidth / 3,
          display: "flex",
          justifyContent: "flex-start",
          marginRight: "110px",
        }}
      >
        {/* <span className="p-input-icon-right w-full md:w-auto">
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto"
        />
      </span> */}
      </div>
      <div>
        <Button
          // label="New"
          icon="pi pi-plus"
          className="p-button-success p-mr-2"
          // onClick={openNew}
          tooltip="New"
          tooltipOptions={{ position: "bottom" }}
          disabled={true}
        />
        <Button
          icon="pi pi-upload"
          className="p-button-help p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
          style={{ marginLeft: "20px" }}
        />
      </div>
    </div>
  );

  const actionBodyEditTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          //onClick={() => editRow(rowData)}
          tooltip="Edit"
          tooltipOptions={{ position: "bottom" }}
          disabled={true}
        />
      </React.Fragment>
    );
  };

  const actionBodyDeleteTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          //onClick={() => confirmDeleteRow(rowData)}
          tooltip="Delete"
          tooltipOptions={{ position: "bottom" }}
          disabled={true}
        />
      </React.Fragment>
    );
  };

  const fetchMachines = async (machine_id) => {
    const p_param = {
      p_machine_id: machine_id,
    };
    console.log(p_param);
    try {
      const response = await loadApi(
        p_param,
        "/api/setup/machineAddresses/selRows"
      );
      console.log("Fetched Address:", response.data);

      if (response.data.length > 0) {
        const successFlag = response.data[0];
        if (successFlag === "Y") {
          setMachines((prevMachines) => ({
            ...prevMachines,
            [machine_id]: response.data[1],
          }));
        } else {
          setMachines((prevMachines) => ({
            ...prevMachines,
            [machine_id]: [],
          }));
        }
      } else {
        setMachines((prevMachines) => ({ ...prevMachines, [machine_id]: [] }));
      }
    } catch (error) {
      console.error("Error fetching machines:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    expandedRowId.forEach((machine_id) => fetchMachines(machine_id));
  }, [expandedRowId]);

  const drillDownTemplate = (rowData) => {
    const isExpanded = expandedRowId.includes(rowData.machine_id);

    return (
      <Button
        icon={isExpanded ? "pi pi-chevron-down" : "pi pi-chevron-right"}
        className={`p-button-rounded p-button-sm ${
          isExpanded ? "p-button-danger" : "p-button-primary"
        }`}
        onClick={() => toggleRowExpansion(rowData.machine_id)}
        tooltip="Detail"
        tooltipOptions={{ position: "bottom" }}
        style={{ fontSize: "1rem" }}
      />
    );
  };

  const toggleRowExpansion = (rowId) => {
    setExpandedRowId((prevExpandedRowId) =>
      prevExpandedRowId.includes(rowId)
        ? prevExpandedRowId.filter((id) => id !== rowId)
        : [...prevExpandedRowId, rowId]
    );
  };

  const rowExpansionTemplate = (data) => {
    const machineData = machines[data.machine_id] || [];
    const exportCSV1 = () => {
      dt1.current.exportCSV();
    };

    return (
      <div className="p-3">
        <div className="header-container">
          <div className="header-container p-1">
            <style>
              {`
                input::placeholder {
                  font-size: 17px;
                }
              `}
            </style>
            <span className="p-input-icon-right w-full md:w-auto">
              <InputText
                type="search"
                onInput={(e) => setGlobalFilter1(e.target.value)}
                placeholder="Search..."
                className="w-full lg:w-auto text-sm p-3"
                style={{ fontSize: "17px" }}
              />
            </span>
            <Button
              icon="pi pi-upload"
              className="p-button-help p-button-rounded"
              onClick={exportCSV1}
              tooltip="Export"
              tooltipOptions={{ position: "bottom" }}
              style={{ marginLeft: "20px" }}
            />
          </div>
        </div>
        <DataTable
          ref={dt1}
          value={machineData}
          responsiveLayout="scroll"
          globalFilter={globalFilter1}
        >
          <Column field="machine_name" header="Machine Name" sortable></Column>
          <Column
            field="machine_address_code"
            header="Address Code"
            sortable
          ></Column>
          <Column
            field="machine_address_name"
            header="Address Name"
            sortable
          ></Column>
          <Column
            field="plc_hmi_mgmt_type_code"
            header="Data Mgmt"
            sortable
          ></Column>
          <Column
            field="address_type_code"
            header="Address Type"
            sortable
          ></Column>
          <Column
            field="address_value_type_code"
            header="Value Type"
            sortable
          ></Column>
          <Column field="opc_contents" header="OPC Contents" sortable></Column>
          <Column
            field="opc_external_ref"
            header="OPC Reference"
            sortable
          ></Column>
          <Column field="opc_model" header="OPC Model" sortable></Column>
          <Column
            field="is_machine_stop"
            header="Is MachStop"
            sortable
          ></Column>
          <Column
            field="machine_wait_time_sort_order"
            header="Sort Order"
            sortable
          ></Column>
          <Column field="active_flag" header="Active" sortable></Column>
        </DataTable>
      </div>
    );
  };
  const TCTemplate = (rowData) => {
    return numeral(rowData.thereotical_capacity).format("0,0");
  };
  const PLTemplate = (rowData) => {
    return numeral(rowData.production_line_number).format("0,0");
  };
  const PSTemplate = (rowData) => {
    return numeral(rowData.production_speed).format("0,0");
  };
  const TETemplate = (rowData) => {
    return numeral(rowData.target_efficiency).format("0,0");
  };
  const WTSOTemplate = (rowData) => {
    return numeral(rowData.wait_time_sort_order).format("0,0");
  };
  const TCNumber = numeral(row.thereotical_capacity).format("0,0");
  const PLNumber = numeral(row.production_line_number).format("0,0");
  const PSNumber = numeral(row.production_speed).format("0,0");
  const TENumber = numeral(row.target_efficiency).format("0,0");
  const WTSONumber = numeral(row.wait_time_sort_order).format("0,0");

  // thereotical_capacity;
  // production_line_number;
  // production_speed;
  // target_efficiency;
  // wait_time_sort_order;
  const readBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-primary"
          onClick={() => showProduct(rowData)}
          tooltip="View"
          tooltipOptions={{ position: "bottom" }}
        />
      </React.Fragment>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="col mx-2">
        {isLoading ? getLoadingHeaderContents() : getLoadedHeaderContents()}
        {!isLoading && (
          <div className="card">
            {/* <div className="text-3xl text-800 font-bold mb-4">Setup Machines</div> */}
            <DataTable
              ref={dt}
              value={rows}
              selection={selectedRows}
              onSelectionChange={(e) => setSelectedRows(e.value)}
              dataKey="machine_id"
              scrollable
              scrollHeight="flex"
              className="custom-datatable-border"
              tableStyle={{ minWidth: "50rem" }}
              paginator
              rows={pecaPageRows}
              rowsPerPageOptions={pecaRowsPerPageOptions}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} machines"
              globalFilter={globalFilter}
              header={header}
              emptyMessage="No machines found"
              stripedRows
              showGridlines
              expandedRows={expandedRowId.map((machine_id) =>
                rows.find((rows) => rows.machine_id === machine_id)
              )}
              onRowToggle={(e) => {
                const rowId = e.data ? e.data.machine_id : null;
                setExpandedRowId((prev) =>
                  prev.includes(rowId)
                    ? prev.filter((machine_id) => machine_id !== rowId)
                    : [...prev, rowId]
                );
              }}
              rowExpansionTemplate={rowExpansionTemplate}
              responsiveLayout="scroll"
              rowExpansion
            >
              {/* <Column
                body={drillDownTemplate}
                style={{ width: "0.75rem", textAlign: "center" }}
              /> */}
              <Column
                body={readBodyTemplate}
                exportable={false}
                style={{ width: "0.75rem" }}
              ></Column>
              <Column
                body={drillDownTemplate}
                style={{ width: "3rem", textAlign: "center" }}
              />
              <Column
                field="machine_id"
                header="id"
                sortable="false"
                hidden="true"
                exportable={false}
                style={{ whiteSpace: "nowrap" }}
              ></Column>
              <Column
                field="machine_name"
                header="Machine Name"
                sortable
                style={{ whiteSpace: "nowrap" }}
              ></Column>
              <Column
                field="machine_description"
                header="Description"
                sortable
                style={{ whiteSpace: "nowrap" }}
              ></Column>
              <Column
                field="opc_machine_name"
                header="OPC Machine Name"
                sortable
                style={{ whiteSpace: "nowrap" }}
              ></Column>
              <Column
                field="opc_ip_address"
                header="Opc Ip Address"
                sortable
                style={{ whiteSpace: "nowrap" }}
              ></Column>
              <Column
                field="dxp_opc_comm_name"
                header="Dxp Comm Name"
                sortable
                style={{ whiteSpace: "nowrap" }}
              ></Column>
              <Column
                field="dxp_file_prefix"
                header="Dxp File Prefix"
                sortable
                style={{ whiteSpace: "nowrap" }}
              ></Column>
              <Column
                field="production_line_number"
                header="Line#"
                sortable
                body={PLTemplate}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              ></Column>
              <Column
                field="process_name"
                header="Process"
                sortable
                style={{ whiteSpace: "nowrap" }}
              ></Column>

              <Column
                field="thereotical_capacity"
                header="Theoretical Capacity"
                sortable
                body={TCTemplate}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              ></Column>

              <Column
                field="target_efficiency"
                header="Target Efficiency"
                sortable
                body={TETemplate}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              ></Column>
              <Column
                field="production_speed"
                header="Production Speed"
                sortable
                body={PSTemplate}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              ></Column>

              <Column
                field="wait_time_sort_order"
                header="Sort Order"
                sortable
                body={WTSOTemplate}
                style={{ whiteSpace: "nowrap", textAlign: "right" }}
              ></Column>
              <Column
                field="prod_enabled_flag"
                header="Prod Enabled"
                sortable
                style={{ whiteSpace: "nowrap" }}
              ></Column>
              <Column
                field="start_date"
                header="Start Date Active"
                body={startDateBodyTemplate}
                sortable
                style={{ whiteSpace: "nowrap" }}
              ></Column>
              <Column
                field="end_date"
                header="End Date Active"
                body={endDateBodyTemplate}
                sortable
                style={{ whiteSpace: "nowrap" }}
              ></Column>
              <Column
                body={actionBodyEditTemplate}
                style={{ width: "0.75rem" }}
              ></Column>
              <Column
                body={actionBodyDeleteTemplate}
                style={{ width: "0.75rem" }}
              ></Column>
            </DataTable>
          </div>
        )}

        {/* <Dialog
          visible={rowDialog}
          style={{ width: "450px" }}
          header="Machine Details"
          modal
          className="p-fluid"
          onHide={hideDialog}
        >
          <div className="p-field">
            <label htmlFor="machine_name">Machine Name</label>
            <InputText
              id="machine_name"
              value={row.machine_name}
              onChange={(e) => onInputChange(e, "machine_name")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !row.machine_name,
              })}
            />
            {submitted && !row.machine_name && (
              <small className="p-error">Machine Name is required.</small>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="opc_machine_name">OPC Machine Name</label>
            <InputText
              id="opc_machine_name"
              value={row.opc_machine_name}
              onChange={(e) => onInputChange(e, "opc_machine_name")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !row.opc_machine_name,
              })}
            />
            {submitted && !row.opc_machine_name && (
              <small className="p-error">OPC Machine Name is required.</small>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="dxp_file_prefix">Dxp File Prefix</label>
            <InputText
              id="dxp_file_prefix"
              value={row.dxp_file_prefix}
              onChange={(e) => onInputChange(e, "dxp_file_prefix")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !row.dxp_file_prefix,
              })}
            />
            {submitted && !row.dxp_file_prefix && (
              <small className="p-error">Dxp File Prefix is required.</small>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="opc_ip_address">Opc Ip Address</label>
            <InputText
              id="opc_ip_address"
              value={row.opc_ip_address}
              onChange={(e) => onInputChange(e, "opc_ip_address")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !row.opc_ip_address,
              })}
            />
            {submitted && !row.opc_ip_address && (
              <small className="p-error">Opc Ip Address is required.</small>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="machine_description">Description</label>
            <InputTextarea
              id="machine_description"
              value={row.machine_description}
              onChange={(e) => onInputChange(e, "machine_description")}
              required
              rows={3}
              cols={20}
            />
          </div>
          <div className="p-field">
            <label htmlFor="production_speed">Production Speed</label>
            <InputNumber
              id="production_speed"
              value={row.production_speed}
              onValueChange={(e) => onInputChange(e, "production_speed")}
              mode="decimal"
              minFractionDigits={2}
            />
          </div>
          <div className="p-field">
            <label htmlFor="target_efficiency">Target Efficiency</label>
            <InputNumber
              id="target_efficiency"
              value={row.target_efficiency}
              onValueChange={(e) => onInputChange(e, "target_efficiency")}
              mode="decimal"
              minFractionDigits={2}
            />
          </div>
          <div className="p-field">
            <label htmlFor="thereotical_capacity">Theoretical Capacity</label>
            <InputNumber
              id="thereotical_capacity"
              value={row.thereotical_capacity}
              onValueChange={(e) => onInputChange(e, "thereotical_capacity")}
              mode="decimal"
              minFractionDigits={2}
            />
          </div>
          <div className="p-field">
            <label htmlFor="wait_time_sort_order">Sort Order</label>
            <InputNumber
              id="wait_time_sort_order"
              value={row.wait_time_sort_order}
              onValueChange={(e) => onInputChange(e, "wait_time_sort_order")}
            />
          </div>
          <div className="p-field-checkbox">
            <Checkbox
              inputId="prod_enabled_flag"
              value={row.prod_enabled_flag}
              onChange={(e) => onInputChange(e, "prod_enabled_flag")}
              checked={row.prod_enabled_flag === "Y"}
            />
            <label htmlFor="prod_enabled_flag">Prod Enabled</label>
          </div>
          <div className="p-field">
            <label htmlFor="start_date">Start date</label>
            <InputText
              id="start_date"
              value={row.start_date}
              onChange={(e) => onInputChange(e, "start_date")}
            />
          </div>
          <div className="p-field">
            <label htmlFor="end_date">End date</label>
            <InputText
              id="end_date"
              value={row.end_date}
              onChange={(e) => onInputChange(e, "end_date")}
            />
          </div>
          <Button
            label="Cancel"
            icon="pi pi-times"
            className="p-button-text"
            onClick={hideDialog}
          />
          <Button
            label="Save"
            icon="pi pi-check"
            className="p-button-text"
            onClick={saveRow}
          />
        </Dialog> */}

        {/* <Dialog
          visible={deleteRowDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteRowDialogFooter}
          onHide={hideDeleteRowDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            {row && (
              <span>
                Are you sure you want to delete <b>{row.machine_name}</b>?
              </span>
            )}
          </div>
        </Dialog> */}

        <Dialog
          visible={showproductDialog}
          style={{ width: "1400px" }}
          header="Setup Machines"
          modal
          className="p-fluid"
          onHide={hideDialog}
        >
          <div className="grid-container-machines">
            <div className="grid-item">
              <h3>Machine Details</h3>
              <hr />
              <div className="info-row">
                <label htmlFor="organization_code">Machine Name</label>
                <div className="box1">{row.machine_name}</div>
              </div>
              <div className="info-row">
                <label htmlFor="allow_negative_inventory_mean">
                  Machine Description
                </label>
                <div className="box1">{row.machine_description}</div>
              </div>
            </div>
            <div className="grid-item">
              <h3>OPC/DXP Details</h3>
              <hr />
              <div className="info-row">
                <label htmlFor="default_ht_aging_hours">OPC Machine Name</label>
                <div className="box1" style={{ textAlign: "right" }}>
                  {row.opc_machine_name}
                </div>
              </div>
              <div className="info-row">
                <label htmlFor="default_rt_aging_hours">OPC IP Address</label>
                <div className="box1" style={{ textAlign: "right" }}>
                  {row.opc_ip_address}
                </div>
              </div>
              <div className="info-row">
                <label htmlFor="default_rt_aging_hours">Dxp Comm Name</label>
                <div className="box1" style={{ textAlign: "right" }}>
                  {row.dxp_opc_comm_name}
                </div>
              </div>
              <div className="info-row">
                <label htmlFor="default_rt_aging_hours">Dxp File Prefix</label>
                <div className="box1" style={{ textAlign: "right" }}>
                  {row.dxp_file_prefix}
                </div>
              </div>
            </div>
            <div className="grid-item">
              <h3>Production/Process Details</h3>
              <hr />
              <div className="info-row">
                <label htmlFor="auto_start_mg_shift_hrs_bef">
                  Production Line Number
                </label>
                <div className="box1" style={{ textAlign: "right" }}>
                  {PLNumber}
                </div>
              </div>
              <div className="info-row">
                <label htmlFor="auto_end_mg_shift_hrs_aft">Process Name</label>
                <div className="box1" style={{ textAlign: "right" }}>
                  {row.process_name}
                </div>
              </div>
            </div>
            <div className="grid-item">
              <h3>Other Details</h3>
              <hr />
              <div className="info-row">
                <label htmlFor="conc_pgm_pdcs_user_name">
                  Theoretical Capacity
                </label>
                <div className="box1">{TCNumber}</div>
              </div>
              <div className="info-row">
                <label htmlFor="conc_pgm_rpt_user_name">
                  Target Efficiency
                </label>
                <div className="box1">{TENumber}</div>
              </div>
              <div className="info-row">
                <label htmlFor="conc_pgm_rpt_resp_name">Production Speed</label>
                <div className="box1">{PSNumber}</div>
              </div>
              <div className="info-row">
                <label htmlFor="conc_pgm_rpt_resp_appl_name">
                  Wait Time Sort Order
                </label>
                <div className="box1">{WTSONumber}</div>
              </div>
            </div>
            <div className="grid-item">
              <h3>Start/End Date Details</h3>
              <hr />
              <div className="info-row">
                <label htmlFor="log_prefix_name">Start Date</label>
                <div className="box1">{row.start_date}</div>
              </div>
              <div className="info-row">
                <label htmlFor="enable_db_log_meaning">End Date</label>
                <div className="box1">{row.end_date}</div>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog
          header="API Error"
          visible={errorDialogVisible}
          style={{ width: "400px" }}
          onHide={hideDialog}
          footer={
            <Button
              label="OK"
              onClick={hideDialog}
              className="p-button-primary p-button-rounded"
            />
          }
        >
          <p>{errorMessage}</p>
        </Dialog>
      </div>
    </div>
  );
};

export default MachinesPG;
