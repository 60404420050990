import React, { useEffect, useState } from "react";
import { loadApi } from "services/appApi";
import moment from "moment";
import ShowVACheckChart from "pages/charts/VAChecker/ShowVACheckChart";
import "primeicons/primeicons.css";
import "assets/css/charts_form_controls.css";

function VACheckFormControlListSelector(props) {
  const [processId, setProcessId] = useState();
  const [machineId, setMachineId] = useState();
  const [machines, setMachines] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [targetEfficiency, setTargetEfficiency] = useState();
  const [wasDataFetchingSuccessful, setWasDataFetchingSuccessful] = useState(true);
  const [error, setError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [flag, setFlag] = useState('');
  const [nFlagErrorMsg, setnFlagErrorMsg] = useState('');
  const [chartForm, setChartForm] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [hasAttemptedFetch, setHasAttemptedFetch] = useState(false);
  const [processRenderedAsRadio, setProcessRenderedAsRadio] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const firstRowProcessId = props.processes[0].id;
        setProcessId(firstRowProcessId);
        console.log(firstRowProcessId);
        const filteredMachines = props.machines.filter(
          (data) => data.process_id === firstRowProcessId
        );
        setMachines(filteredMachines);
        setMachineId(
          filteredMachines.length > 0 ? filteredMachines[0].id : null
        );
        console.log(filteredMachines);

        //setMachines(filteredMachines);

        const yesterday = moment().subtract(1, "days").set({
          hour: 7,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        let formattedStartDate = yesterday.format("yyyy-MM-DD HH:mm");
        setStartDate(formattedStartDate);

        const tomorrow = moment().add(0, "days").set({
          hour: 6,
          minute: 59,
          second: 0,
          millisecond: 0,
        });
        let formattedEndDate = tomorrow.format("yyyy-MM-DD HH:mm");
        setProcessRenderedAsRadio(props.processes.length <= 2);
        setEndDate(formattedEndDate);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [props.processes, props.machines]);

  const handleLoadChart = async () => {
    let selectedMachineRec = machines.find((dt) => dt.id == machineId);
    console.log(selectedMachineRec);
    const p_param = {
      p_machine_id: selectedMachineRec.id,
      p_start_datetime: startDate,
      p_end_datetime: endDate,
    };
    console.log(p_param);

    setIsLoading(true);
    setHasAttemptedFetch(true); 
    setChartForm({
      ...chartForm,
      machineDesc: selectedMachineRec.description,
      data: p_param,
    });
    console.log(chartForm);

    await loadApi(p_param, "/api/chart/getVaChkrRunTotalData")
      .then(async (result) => {
        //    await loadApi(p_param, "GetHourlyMachineEffOpTimeByIdAndTimespan").then(async (result) => {
        if (result) {
          console.log("Result: ",result);
          let successFlag = result.data[0];
          setFlag(successFlag);
          if (successFlag === "Y") {
            if (result.data[1].length > 0) {
              setChartData(result.data[1]);
              console.log("result.data[1]", result.data[1]);
              setWasDataFetchingSuccessful(false);
              setError(false);
            } else {
              setErrorStatus(204);
              setError(true);
              setWasDataFetchingSuccessful(true);
            }
          } else {
            setError(true);
            if(result.data[2] && result.data[2].length > 0){
              const extractedErrMsg = result.data[2].map((item) => item.error_msg);
              setnFlagErrorMsg(extractedErrMsg);
            }
          }
          setIsLoading(false);
        } else {
          setWasDataFetchingSuccessful(true);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setErrorStatus(err.response ? err.response.status : null);
        setErrorMessage(err.message)
        setIsLoading(false);
        setError(true);
      });
  };

  const handleProcessChange = (e) => {
    const selectedProcessId = e.target.value;
    setProcessId(selectedProcessId);

    //setMachines(filteredMachines);
  };

  const handleMachineChange = (e) => {
    setMachineId(parseInt(e.target.value));
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

    const getProcessControl = () => (
        <React.Fragment>
        <div className="col-auto px-1">
        <label
          className={
            props.processes.length === 1 ? "form-label mb-3" : "form-label"
          }
        >
          Process
        </label>
          {processRenderedAsRadio ? (
            <div className="form-check">
              {props.processes.map((input) => (
                <div key={input.id}>
                  <input
                    inputId={`${props.chartId}ProcessRadBtn${input.id}`}
                    className="form-check-input"
                    type="radio" 
                    name={`${props.chartId}ProcessRadBtn`}
                    value={input.id}
                    onChange={handleProcessChange}
                    checked={processId == input.id}
                    disabled={isLoading}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`${props.chartId}ProcessRadBtn${input.id}`}
                  >
                    {input.description}
                  </label>
                </div>
              ))}
            </div>
          ) : (
            <select
              className="form-select"
              id={`${props.chartId}ProcessDrpDnList`}
              name={`${props.chartId}ProcessDrpDnList`}
              onChange={handleProcessChange}
              value={processId || ""}
              disabled={isLoading}
            >
              {props.processes.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.description}
                </option>
              ))}
            </select>
          )}
        </div>
      </React.Fragment>
    );

  const getMachineControl = () => (
    <React.Fragment>
      <div className="col-auto px-1">
        <label className={
            props.machines.length === 1 ? "form-label mb-3" : "form-label"
          } htmlFor="controlItemSelect">
          Machine
        </label>

        <div className="form-check" onChange={handleMachineChange}>
          {machines.map((input) => (
            <div key={input.id}>
              <input
                className="form-check-input"
                type="radio"
                id={`${props.chartId}MachineRadBtn${input.id}`}
                name={`${props.chartId}MachineRadBtn`}
                value={input.id}
                checked={machineId === input.id}
                disabled={isLoading}
              />
              <label
                className="form-check-label"
                htmlFor={`${props.chartId}MachineRadBtn${input.id}`}
              >
                {input.description}
              </label>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );

 const getStartEndDateControls = () => (
     <React.Fragment>
       <div className="col-auto px-1">
         <div className="row justify-content-center m-0">
           <div className="col-auto form-group ps-0 pe-1 width-sm-auto">
         <label className="form-label">Start Date</label>
         <div className="input-group date">
         <input
           type="datetime-local"
           id={props.chartId + "StartDateTime"}
           name={props.chartId + "StartDateTime"}
           defaultValue={startDate}
           onChange={handleStartDateChange}
           className="input-outline1"
           disabled={isLoading}
         />
         </div>
       </div>
       <div className="col-auto form-group ps-1 pe-0 width-sm-auto">
         <label className="form-label">End Date</label>
         <div className="input-group date">
         <input
           type="datetime-local"
           id={props.chartId + "EndDateTime"}
           name={props.chartId + "EndDateTime"}
           defaultValue={endDate}
           onChange={handleEndDateChange}
           className="input-outline1"
           disabled={isLoading}
         />
         </div>
       </div>
       </div>
       </div>
     </React.Fragment>
   );

   const getLoadButtonControl = () => (
      <React.Fragment>
          <div className="col-auto d-flex align-items-center justify-content-center ps-1 pe-0" style={{paddingTop:'31px'}}>
          <button type="button" id={props.chartId + "chartAreaId"} className="btn btn-primary" onClick={handleLoadChart}
              disabled={isLoading}><i
              className={
                isLoading ? "fa-duotone fa-fw fa-rotate fa-spin" : "fa-duotone fa-fw fa-rotate"
              }
            ></i>{" "}
            <span>{isLoading ? "Loading" : "Load"}</span></button>
          </div>
      </React.Fragment>
    );

  return (
    <div className="col mx-2">
      <div className="row">
        <div className="col">
      <div className="row justify-content-center">
        {getProcessControl()}
        {getMachineControl()}
        {getStartEndDateControls()}
        {getLoadButtonControl()}
      </div>
      {error && hasAttemptedFetch &&  (
         <div className="row mt-5 justify-content-md-center">
          <div className="col-6">
            <div className="card border-secondary">
              <div className="card-header bg-dark text-white">
                  {errorStatus === 204 && (
                    <h3>No Matching Results!</h3>
                  )}
                  {errorStatus === 500 && (
                    <h3>Data/export unavailable</h3>
                  )}
                  {errorStatus && ![204, 500].includes(errorStatus) && (
                    <h3>Unable to Pull Chart Data</h3>
                  )}
                  {flag === "N" && (
                    <h3>No Matching Results!</h3>
                  )}
              </div>
              <div className="card-body card-body-error rounded-bottom">
                  {/* Conditional rendering based on error status */}
                  {errorStatus === 204 && (
                    <h6 style={{fontWeight:"normal"}}>There are no results found.</h6>
                  )}
                  {errorStatus && ![204].includes(errorStatus) && (
                    <>
                    <h5>Charts were not loaded due to...</h5>
                    <ul class="mb-0">
                    <li>
                      {errorMessage}
                    </li>
                  </ul>
                  </>
                  )}
                  {flag === "N" && (
                    <ul class="mb-0" style={{listStyleType: 'none', padding: 0, margin: 0}}>
                      {nFlagErrorMsg.map((error_msg, index) => (
                        <li key={index}>
                          {error_msg}
                        </li>
                      ))}   
                  </ul>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
      
      {!wasDataFetchingSuccessful && !error && (
       <div className="row my-3">
          <div className="col mx-2">
              <ShowVACheckChart
                chartData={chartData}
                chartForm={chartForm}
                targetEff={targetEfficiency}
                id={props.chartId + "OptimeChart1"}
              />
            </div>
          </div>
      )}
    </div>
    </div>
    </div>
  );
}

export default VACheckFormControlListSelector;
