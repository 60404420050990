import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "assets/scss/dashboard.scss";
import "assets/css/charts_form_controls.css";
import "assets/css/grid_table.css";
import React, { useState, useRef, useLayoutEffect,useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { getDataApi, saveApi, loadApi } from "services/appApi";
import { pecaPageRows, pecaRowsPerPageOptions } from "pages/common/envPECA";

const PollDataColumns = () => {
    const emptyRow = {
        file_name: "",
        machine_name: "",
        column_number: "",
        dxp_tag_name: "",
        machine_address_code: "",
        machine_address_name: "",
        is_production_count: false,
        is_machine_stop: false,
        is_duration_second: false,
        is_duration_minute: false,
        ora_error_message: "",
        file_id: "",
        dxp_file_prefix: "",
        process_name: "",
        production_line_number: "",
        creation_date: ""
    };

    const [rows, setRows] = useState([]);
    const [rowDialog, setRowDialog] = useState(false);
    const [showproductDialog, setShowProductDialog] = useState(false);
    const [row, setRow] = useState(emptyRow);
    const [selectedRows, setSelectedRows] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorDialogVisible, setErrorDialogVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loadingErrors, setLoadingErrors] = useState([]);
    const [wasDataFetchingSuccessful, setWasDataFetchingSuccessful] =useState(true);
    const [fileName, setFileName]=useState("");
    const [machineName, setMachineName]=useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const toast = useRef(null);
    const dt = useRef(null);
    const p_param = {
        p_file_id:null,
        p_file_name:null,
        p_process_id:null,
        p_line_number:null,
        p_machine_id:null,
        p_start_datetime:null,
        p_end_datetime:null,
        p_machine_name_like:null,
      };
      useEffect(() => {
        const fetchData = async () => {
            const yesterday = moment().subtract(1, "days").set({
              hour: 7,
              minute: 0,
              second: 0,
              millisecond: 0,
            });
    
            let formattedStartDate = yesterday.format("yyyy-MM-DD HH:mm");
            setStartDate(formattedStartDate);
    
            const tomorrow = moment().add(0, "days").set({
              hour: 6,
              minute: 59,
              second: 0,
              millisecond: 0,
            });
            let formattedEndDate = tomorrow.format("yyyy-MM-DD HH:mm");
            setEndDate(formattedEndDate);
          }
    
        fetchData();
      }, []);
   
    const handleLoadData = async () => {
      const p_param = {
        p_start_datetime: startDate,
        p_end_datetime: endDate,
        p_file_name: fileName,
        p_machine_name_like: machineName,
     };
     console.log(p_param);
      try {
        setIsLoading(true);
        const response = await loadApi(p_param,"/api/txn/getPollDataFileCols");
        console.log("Fetched Data:", response.data);
        console.log(response.data[0]);
        console.log(response.data[1]);
        if (response.data.length > 0) {
          let successFlag = response.data[0];
          if (successFlag === "Y") {
            console.log("success=Y");
            setRows(response.data[1]);
            setIsLoading(false);
            setWasDataFetchingSuccessful(false);
          } else {
            //setRows(null);
            setRows([]);
            setIsLoading(false);
            setWasDataFetchingSuccessful(true);
            const errorInfo = response.data[2];
            // Set the error message and display the dialog
            setErrorMessage(errorInfo);
            setErrorDialogVisible(true);
          }
          //setSearch(response.data);
        } else {
          //setRows(null);
          setRows([]);
          setIsLoading(false);
          setWasDataFetchingSuccessful(true);
        }

        //setRows(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const hideDialog = () => {
        setSubmitted(false);
        setRowDialog(false);
        setErrorDialogVisible(false);
    };

    const editRow = (row) => {
        setRow({ ...row });
        setRowDialog(true);
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].file_id === id) {
                index = i;
                break;
            }
        }
        return index;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };


    const getLoadingHeaderContents = () => {
        return (
          <React.Fragment>
            <div className="row d-flex justify-content-center m-5">
              <div className="col-auto">
                <div className="card shadow-sm border-primary">
                  <div className="card-header bg-primary text-white">
                    <h2 className="mb-0">
                    DXP Poll Data Column Errors
                    </h2>
                  </div>
                  <div className="card-body text-center">
                    <h3>
                      <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                      The page is loading...
                    </h3>
                  </div>
                </div>
              </div>
            </div>
      
          </React.Fragment>
        );
      }

      const getErrrorMsgContents = () => {
        return (
          <React.Fragment>
            <div class="row mt-5 justify-content-md-center">
              <div class="col-3">
                <div class="card border-secondary">
                  <div class="card-header bg-dark text-white">
                  DXP Poll Data Column Errors table is Unavailable
                  </div>
                  <div class="card-body card-body-error rounded-bottom">
                    <ul>
                      {loadingErrors.map((error) => (
                        <li>
                          error
                        </li>
                      ))}
      
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }
      const getSearchControls = () => (
        <React.Fragment>
          <div className="col-auto form-group ps-2 pe-0 d-flex flex-column">
          <label className="form-label">Search</label>
              <InputText
                type="search"
                onInput={(e) => setGlobalFilter(e.target.value)}
                placeholder="Search..."
                className="input-outline" />
                 </div>
        </React.Fragment>
      );
    
      const getFileNameControl = () => (
        <React.Fragment>
          <div className="col-auto form-group ps-2 pe-0 d-flex flex-column">
          <label className="form-label">File Name</label>
                <InputText 
                type="text"
                id = "FileName"
                name="FileName" 
                onChange={(e) => setFileName(e.target.value)}
                placeholder="Enter file name"
                className="input-outline" />
                 </div>
        </React.Fragment>
      );
      const getMachineNameControl = () => (
        <React.Fragment>
          <div className="col-auto form-group ps-2 pe-0 d-flex flex-column">
          <label className="form-label">Machine Name</label>
                <InputText 
                type="text"
                id = "MacName"
                name="MacName" 
                onChange={(e) => setMachineName(e.target.value)}
                placeholder="Enter machine name"
                className="input-outline" />
                 </div>
        </React.Fragment>
      );
      
      
      const getStartEndDateControls = () => (
        <React.Fragment>
          <div className="col-auto form-group ps-2 pe-0 d-flex flex-column">
            <label className="form-label">Start Date</label>
            <input
              type="datetime-local"
              id="StartDateTime"
              name="StartDateTime"
              defaultValue={startDate}
              onChange={handleStartDateChange}
              className="input-outline1"
            />
          </div>
          <div className="col-auto form-group ps-2 pe-0 d-flex flex-column">
            <label className="form-label">End Date</label>
            <input
              type="datetime-local"
              id="EndDateTime"
              name="EndDateTime"
              defaultValue={endDate}
              onChange={handleEndDateChange}
              className="input-outline1"
            />
          </div>
        </React.Fragment>
      );
      
      const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
      };
      
      const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
      };
      
      const getGoButtonControl = () => (
        <React.Fragment>
            <div className="col-auto d-flex align-items-center justify-content-center ps-2 pe-0" style={{paddingTop:'31px'}}>
              <button
                type="button"
                className="btn btn-primary load-button"
                id={"chartAreaId"}
                onClick={handleLoadData}
                disabled={isLoading}
              >
                 <i
            className={
              isLoading ? "fa-duotone fa-fw fa-rotate fa-spin" : "fa-duotone fa-fw fa-rotate"
            }
          ></i>
          <span>{isLoading ? "Loading" : "Go"}</span>
              </button>
            </div>
        </React.Fragment>
        );
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _row = { ...row };
        _row[`${name}`] = val;

        setRow(_row);
    };

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
          {/* <span className="p-input-icon-right w-full md:w-auto">
            <InputText
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search..."
              className="w-full lg:w-auto"
            />
          </span> */}
         <div className="mt-1 md:mt-0 flex md:flex-1 justify-content-end">
            <Button
              icon="pi pi-upload"
              className="p-button-primary p-button-rounded"
              onClick={exportCSV}
              tooltip="Export"
              tooltipOptions={{ position: "bottom" }}
            />
          </div>
        </div>
      );
  const getLoadedHeaderContents = () => {
    return (
      <React.Fragment>
         <div className="header-container">
         <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
           DXP Poll Data Column Errors 
            {/* <div className="header-container p-1">
                <style>
                {`
                  input::placeholder {
                    font-size: 17px;
                  }
                `}
              </style>
            <span className="p-input-icon-right w-full md:w-auto"> 
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
            className="w-full lg:w-auto text-sm p-3"
            style={{ fontSize: '17px' }} 
          />
        </span>
            </div> */}
          </h2>
        </div>
      </React.Fragment>
    );
  }

  const readBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded mr-2"
          onClick={() => editRow(rowData)}
          tooltip="View"
          tooltipOptions={{ position: "bottom" }}
        />
        {/* <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => confirmDeleteRow(rowData)}
          tooltip="Delete"
          tooltipOptions={{ position: "bottom" }}
        /> */}
      </React.Fragment>
    );
  };
  const formatCreationDate = (rowData) => {
    return moment(rowData.creation_date).format('YYYY-MM-DD HH:mm:ss');
  };
  const formatDate = moment(row.creation_date).format('YYYY-MM-DD HH:mm:ss');

    return (
        <div>
          <div className="col mx-2">
            <Toast ref={toast} />
            {getLoadedHeaderContents()}
      {loadingErrors.length > 0 && getErrrorMsgContents()}
      <div className="row">
      <div className="col">
        <div className="row justify-content-center">
          {getSearchControls()} 
          {getStartEndDateControls()} 
          {getFileNameControl()}
          {getMachineNameControl()}
          {getGoButtonControl()}
        </div>
      {!wasDataFetchingSuccessful && (
            <div className="card">
                {/* <div className="text-3xl text-800 font-bold mb-4">DXP Poll Data Column Errors</div> */}
                <DataTable
                    ref={dt}
                    value={rows}
                    scrollable scrollHeight="flex"
                    selection={selectedRows}
                    onSelectionChange={(e) => setSelectedRows(e.value)}
                    dataKey="file_id"
                    className="custom-datatable-border"
                    tableStyle={{ minWidth: '50rem' }}
                    paginator
                    rows={pecaPageRows}
                    rowsPerPageOptions={pecaRowsPerPageOptions}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data"
                    globalFilter={globalFilter}
                    header={header}
                    emptyMessage="No data found"
                    stripedRows
                    showGridlines
                >
                    <Column body={readBodyTemplate} exportable={false} style={{width:'0.75rem' }}></Column>
                    <Column field="file_name" header="File Name" sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="creation_date" header="Date" sortable body={formatCreationDate} style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="machine_name" header="Machine Name" sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="column_number" header="Col#" sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="dxp_tag_name" header="Tag Name" sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="ora_error_message" header="Ora Error Message" sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="machine_address_code" header="Firt Ftp Date" sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="machine_address_name" header="Address Name" sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="is_production_count" header="ProdCount?" sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="is_machine_stop" header="Machine Stop?" sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="is_duration_second" header="DurInSecs?" sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="is_duration_minute" header="DurInMins?" sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="file_id" header="File ID" sortable hidden exportable={false}></Column>
                    <Column field="dxp_file_prefix" header="DXP File Prefix" sortable hidden exportable={false}></Column>
                    <Column field="process_name" header="Process Name" sortable hidden exportable={false}></Column>
                    <Column field="production_line_number" header="Production Line No" sortable hidden exportable={false}></Column>
                </DataTable>
            </div>
            )}
            </div>
            </div>           
            <Dialog visible={rowDialog} style={{ width: "450px" }} header="DXP Poll Data Column Errors" modal className="p-fluid"  onHide={hideDialog}>
            <label htmlFor="file_name">File Name</label>
                    <div className="box">
                        {row.file_name}
                    </div>
                <label htmlFor="creation_date">Date</label>
                    <div className="box">
                        {formatDate}
                    </div>

                <label htmlFor="machine_name">Machine Name</label>
                    <div className="box">
                        {row.machine_name}
                    </div>

                <label htmlFor="column_number">Col#</label>
                    <div className="box">
                        {row.column_number}
                    </div>
                    <label htmlFor="dxp_tag_name">Tag Name</label>
                    <div className="box">
                        {row.dxp_tag_name}
                    </div>
                <label htmlFor="ora_error_message">Ora Error Message</label>
                    <div className="box">
                        {row.ora_error_message}
                    </div>
                <label htmlFor="machine_address_code">Firt Ftp Date</label>
                    <div className="box">
                        {row.machine_address_code}
                    </div>

                <label htmlFor="machine_address_name">Address Name</label>
                    <div className="box">
                        {row.machine_address_name}
                    </div>
                    <label htmlFor="is_production_count">ProdCount?</label>
                    <div className="box">
                        {row.is_production_count}
                    </div>

                <label htmlFor="is_machine_stop">Machine Stop?</label>
                    <div className="box">
                        {row.is_machine_stop}
                    </div>

                <label htmlFor="is_duration_second">DurInSecs?</label>
                    <div className="box">
                        {row.is_duration_second}
                    </div>


                <label htmlFor="is_duration_minute">DurInMins?</label>
                    <div className="box">
                        {row.is_duration_minute}
                    </div>

            </Dialog>
            <Dialog 
                header="API Error" 
                visible={errorDialogVisible} 
                style={{ width: '400px' }} 
                onHide={hideDialog} 
                footer={
                    <Button label="OK" onClick={hideDialog} className="p-button-primary p-button-rounded" />
                }
            >    
             <p>

                    {errorMessage}
                </p>
            </Dialog>
            </div>
        </div>
    );
};

export default PollDataColumns;
